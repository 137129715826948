import { Component, OnInit } from '@angular/core';
import { InfluencerService, LanguageService } from '@yim/server/services';
import { HelperService, LoaderService, TooltipService } from '@yim/ui/services';

import { NotificationService } from '../../services';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})

export class DefaultLayoutComponent implements OnInit {

  public showLoader: boolean = false;
  public showHeader: boolean = false;

  constructor(private _notificationService: NotificationService,
              private _tooltipService: TooltipService,
              private _languageService: LanguageService,
              private _influencerService: InfluencerService) {
  }

  ngOnInit(): void {
    LoaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
    });
    HelperService.setCommonFields();
    this.onResize();
  }

  onResize() {
    this.showHeader = window.screen.width < 992;
  }

  populateNotification() {
    this._notificationService.getNotification();
  }
}
