import {CanRunService} from '@yim/server/services/can-run.service';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {defineLocale} from 'ngx-bootstrap';
import {deLocale} from 'ngx-bootstrap/locale';

import {LanguageService} from '@yim/server/services';
import {environment} from '../environments/environment';

@Component({
  selector: 'body',
  template: `
    <ng-container *ngIf="canRunService.CannotReason | async as reason; else page">
      <p>
        {{'ADVERTISER.DISABLE_ADBLOCK'|translate}}
      </p>
    </ng-container>
    <ng-template #page>
      <router-outlet></router-outlet>
      <toaster-container></toaster-container>
    </ng-template>
  `
})
export class AppComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              public canRunService: CanRunService,
              private translate: TranslateService,
              private _lang: LanguageService) {
    defineLocale('de', deLocale);
    _lang.values.subscribe((response: any) => {
      translate.setDefaultLang(`${response.default_language}`);
    });

    if (environment.CHATRA_ID) {
      (function (d, w, c) {
        // @ts-ignore
        w.ChatraID = environment.CHATRA_ID;
        // @ts-ignore
        const s = d.createElement('script');
        w[c] = w[c] || function () {
          (w[c].q = w[c].q || []).push(arguments);
        };
        s.async = true;
        s.src = 'https://call.chatra.io/chatra.js';
        if (d.head) {
          d.head.appendChild(s);
        }
      })(document, window, 'Chatra');
    }
  }

  ngOnInit() {
  }
}
