import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
// import { navItems } from '../_nav';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private messageRoute = '/messenger';
  private applicationRoute = '/applications';

  constructor(private _http: HttpClient) {
  }

  getNotification() {
    this._http.get<any>(`${environment.API}/notification/list`).subscribe(
      (response: any) => {
        for (const notificationItem of response) {
          const badge = {
            variant: 'primary badge-pill',
            text: notificationItem['count'],
            notificationId: notificationItem['id']
          };
          // switch (notificationItem['type']) {
          //   case'message':
          //     this.setNotificationBadgeToNavRoute(navItems, this.messageRoute, badge);
          //     break;
          //   case 'campaign-apply':
          //     this.setNotificationBadgeToNavRoute(navItems, this.applicationRoute, badge);
          //     break;
          // }
        }
      });
  }

  deleteNotification(notificationType) {
    const notificationIdIndexObj = null;
    // switch (notificationType) {
    //   case'message':
    //     notificationIdIndexObj = this.removeNotificationBadgeFromNavRoute(navItems, this.messageRoute);
    //     break;
    //   case 'campaign-apply':
    //     notificationIdIndexObj = this.removeNotificationBadgeFromNavRoute(navItems, this.applicationRoute);
    //     break;
    // }

    // if (notificationIdIndexObj !== null) {
    //   this._http.delete(`${environment.API}/notification/delete/${notificationIdIndexObj.notificationId}`)
    //     .subscribe((response) => {
    //       if (response['status']) {
    //         delete navItems[notificationIdIndexObj.navItemIndex]['badge'];
    //       }
    //     });
    // }
  }

  setNotificationBadgeToNavRoute(navItemsToCheck: any, url: string, badge: any) {
    for (let i = 0; i < navItemsToCheck.length; i++) {
      const navItemToCheck = navItemsToCheck[i];
      if (navItemToCheck.url && navItemToCheck.url === url) {
        navItemToCheck.badge = badge;
      }
      if (navItemToCheck.children && navItemToCheck.children.length > 0) {
        this.setNotificationBadgeToNavRoute(navItemToCheck.children, url, badge);
      }
    }
  }

  removeNotificationBadgeFromNavRoute(navItemsToCheck: any, url: string) {
    for (let i = 0; i < navItemsToCheck.length; i++) {
      const navItemToCheck = navItemsToCheck[i];
      if (navItemToCheck.url && navItemToCheck.url === url) {
        if (navItemToCheck.badge) {
          return {navItemIndex: i, notificationId: navItemToCheck.badge.notificationId};
        }
      }
      if (navItemToCheck.children && navItemToCheck.children.length > 0) {
        const notificationIdIndexObj = this.removeNotificationBadgeFromNavRoute(navItemToCheck.children, url);
        if (notificationIdIndexObj !== null) {
          return notificationIdIndexObj;
        }
      }
    }
    return null;
  }
}
