import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class BlogService {

  constructor(private http: HttpClient) {
  }

  getAllBlogs(page) {
    return this.http.get(environment.BLOG + '?page=' + page, {observe: 'response'});
  }

  getBlogDetails(id) {
    return this.http.get(environment.BLOG + '/' + id);
  }
}
