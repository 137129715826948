import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UiModule } from '@yim/ui';
import { ChartsModule } from 'ng2-charts/ng2-charts';

import { InfluencerCardComponent } from './components/influencer-card-details-v2/influencer-card.component';
import { InfluencerStatsComponent } from './components/influencer-stats/influencer-stats.component';
import { ServerComponent } from './server.component';
import { InfluencerInfoComponent } from './components/influencer-info/influencer-info.component';
import { InfluencerStatsDetailComponent } from './components/influencer-stats-detail/influencer-stats-detail.component';
import { MetaGraphsComponent } from './components/influencer-stats-detail/cards/meta-graphs/meta-graphs.component';
import { AudienceDetailsComponent } from './components/influencer-stats-detail/cards/audience-details/audience-details.component';
import { PostsComponent } from './components/influencer-stats-detail/cards/posts/posts.component';
import { AgeGenderSplitComponent } from './components/influencer-stats-detail/cards/age-gender-split/age-gender-split.component';
import { EngagementSpreadComponent } from './components/influencer-stats-detail/cards/engagement-spread/engagement-spread.component';
import { EngagementRangeComponent } from './components/influencer-stats-detail/cards/engagement-range/engagement-range.component';
import { FollowersLocationComponent } from './components/influencer-stats-detail/cards/followers-location/followers-location.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    ServerComponent,
    InfluencerCardComponent,
    InfluencerStatsComponent,
    InfluencerInfoComponent,
    InfluencerStatsDetailComponent,
    MetaGraphsComponent,
    AudienceDetailsComponent,
    PostsComponent,
    AgeGenderSplitComponent,
    EngagementSpreadComponent,
    EngagementRangeComponent,
    FollowersLocationComponent
  ],
  imports: [
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    UiModule,
    TranslateModule.forChild(),
  ],
  exports: [
    ChartsModule,
    ServerComponent,
    TranslateModule,
    InfluencerCardComponent,
    InfluencerStatsComponent
  ]
})
export class ServerModule { }
