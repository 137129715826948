import { Component, EventEmitter, HostBinding, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { SEARCH_DEMO } from '@yim/search/constants';
import { Common, Influencer as InfluencerModel } from '@yim/server/models';
import { CampaignService, LanguageService, ListsService } from '@yim/server/services';
import { HelperService, LoaderService } from '@yim/ui/services';
import { ToasterService } from 'angular2-toaster';
import * as _ from 'lodash';
import { takeWhile } from 'rxjs/operators';

import { IChannelOption, CHANNEL_OPTIONS, Channel } from './../../models/channel';

type TopAgeBracket = (string | number)[];

@Component({
  selector: 'yim-server-influencer-card',
  templateUrl: './influencer-card.component.html',
  styleUrls: ['./influencer-card.component.scss']
})
export class InfluencerCardComponent implements OnInit, OnDestroy {
  @ViewChild('influencerStatsModal', { static: false }) influencerStatsModal;

  localInfluencer: InfluencerModel = null;
  @Input() set influencer(influencer: InfluencerModel) {

    if (influencer && influencer.age_split) {
      const pairs = _.toPairs(influencer.age_split);
      const sortedAgeBrackets = _.sortBy(pairs, ageBracket => ageBracket[1]);
      const topAgeBrackets =  _.takeRight(sortedAgeBrackets, 3);
      this.topAgeBrackets = topAgeBrackets;
    } else {
      this.topAgeBrackets = [];
    }

    this.localInfluencer = influencer;

    this.ngOnInit();

  }

  get URL() {
    if (!(this.channel && this.localInfluencer)) { return null; }
    if (this.localInfluencer.profile_name) {
      switch (this.channel) {
        case Channel.Instagram:
          return `https://instagram.com/${this.localInfluencer.profile_name}`;
        case Channel.YouTube:
          return `https://youtube.com/user/${this.localInfluencer.profile_name}`;
        case Channel.TikTok:
          return `https://tiktok.com/@${this.localInfluencer.profile_name}`;
      }
    } else {
      switch (this.channel) {
        case Channel.YouTube:
          return `https://youtube.com/channel/${this.localInfluencer.socialId}`;
        default:
          return null;
      }
    }
  }

  @Input() channel = Channel.Instagram;
  @Input() highlighted = false;
  @Input() enableAddToCampaign: boolean = false;
  @Input() enableAddToList: boolean = false;
  @Input() enableRemoveFromList: boolean = false;
  @Input() enableRemoveFromCampaign: boolean = false;
  @Input() enableSetInfluencerPrice: boolean = false;
  @Input() enableShowMyStatusAndPrice: boolean = false;
  @Input() enableAcceptRejectApplication: boolean = false;
  @Input() isRejected: boolean = false;
  @Input() isAccepted: boolean = false;
  @Output() addToCampaignClick = new EventEmitter();
  @Output() removeFromListClick = new EventEmitter();
  @Output() removeFromCampaignClick = new EventEmitter();
  @Output() priceUpdate = new EventEmitter();
  @Output() applicationAcceptClick = new EventEmitter();
  @Output() applicationRejectClick = new EventEmitter();

  @Input() allowEdit = true;
  @Input() allowDetail = true;
  @Input() isDemo = false;
  @HostBinding('class.is-blurred') @Input() isBlurred = false;

  public showInfluencerStats: boolean = false;
  private _array = Array;
  public ObjectKeys = Object.keys;
  topAgeBrackets = <TopAgeBracket>[];
  public listArray: Common[] = [];
  public campaignId: number = null;
  public alive: boolean = true;
  public myStatusBg: string[] = ['', 'text-purple', 'text-primary', 'text-secondary', 'text-light-purple'];

  public priceEditMode: boolean = false;
  public priceForm: FormGroup = null;

  public isInfoCardExpanded: boolean = false;
  public popularPosts: string[] = [];

  public allowExpanding = true;

  constructor(
    private _campaignService: CampaignService,
    private _listsService: ListsService,
    private _toaster: ToasterService,
    private _language: LanguageService,
    private _activatedRoute: ActivatedRoute,
    private _fb: FormBuilder,
    @Inject(SEARCH_DEMO) isDemo = false
  ) {
    this.isDemo = this.isDemo || isDemo;
    this._activatedRoute.params.pipe(takeWhile(() => this.alive)).subscribe((params: Params) => {
      this.campaignId = +params['id'];
    });
  }

  trackByTopAgeBracket(index: number, topAgeBracket: TopAgeBracket) {
    return topAgeBracket[0];
  }

  ngOnInit() {
    if (this.localInfluencer.address !== null && this.localInfluencer.address !== undefined) {
      this.localInfluencer.address = this.localInfluencer.address.replace(/, /g, '\n');
    }
    if (this.enableAddToList) {
      this.listArray = this._listsService.getListArray();
    }
    if (this.enableSetInfluencerPrice) {
      this.priceForm = this._fb.group({
        price: [this.localInfluencer.price, [Validators.required, Validators.min(0.01)]],
        campaignId: [this.campaignId],
        influencerId: [this.localInfluencer.influencerId],
        socialId: [this.localInfluencer.socialId]
      });
    }
    this.popularPosts = _.map(this.localInfluencer.popular_posts, function (post) {
      // todo decide what to use thumbnail or image
      return post.image;
    });

    this.allowExpanding = !!(this.localInfluencer.audience && this.localInfluencer.age_split && this.localInfluencer.location_by_country);
  }

  onClickAddCampaign() {
    if (this.enableAddToCampaign && this.campaignId !== null) {
      requestAnimationFrame(() => {
        LoaderService.show();
        this._campaignService
          .addInfluencerToCampaign(this.campaignId, this.localInfluencer.influencerId, this.localInfluencer.socialId)
          .subscribe(
            (response: any) => {
              LoaderService.hide();
              if (response.status) {
                CampaignService.setCampaignProgressInfluencerAdded(this.campaignId);
                this.addToCampaignClick.emit(this.localInfluencer);
                this._toaster.pop('success', this._language.getTranslatedString('INFLUENCER.INFLUENCER_ADDED'));
              } else {
                this._toaster.pop('warning', response.message);
              }
            },
            error => {
              LoaderService.hide();
              this._toaster.pop('error', this._language.getTranslatedString('MESSAGES.MSG_500'));
            }
          );
      });
    }
  }

  onRemoveFromCampaign() {
    if (this.enableRemoveFromCampaign && this.campaignId !== null) {
      requestAnimationFrame(() => {
        LoaderService.show();
        this._campaignService
          .removeInfluencerToCampaign(this.campaignId, this.localInfluencer.influencerId, this.localInfluencer.socialId)
          .subscribe(
            (response: any) => {
              LoaderService.hide();
              if (response.status) {
                this.removeFromCampaignClick.emit(this.localInfluencer);
                this._toaster.pop(
                  'success',
                  this._language.getTranslatedString('INFLUENCER.INFLUENCER_REMOVED_FROM_CAMPAIGN')
                );
              } else {
                this._toaster.pop('warning', response.message);
              }
            },
            error => {
              LoaderService.hide();
              this._toaster.pop('error', this._language.getTranslatedString('MESSAGES.MSG_500'));
            }
          );
      });
    }
  }

  addInfluencerToList(listID) {
    requestAnimationFrame(() => {
      LoaderService.show();
      this._listsService.addToList(listID, this.localInfluencer.influencerId, this.localInfluencer.socialId).subscribe(
        (response: any) => {
          LoaderService.hide();
          if (response.status) {
            this._toaster.pop('success', this._language.getTranslatedString('INFLUENCER.INFLUENCER_ADDED'));
          } else {
            this._toaster.pop('warning', response.message);
          }
        },
        error => {
          LoaderService.hide();
          this._toaster.pop('error', this._language.getTranslatedString('MESSAGES.MSG_500'));
        }
      );
    });
  }

  getMyStatusLabel(myStatus) {
    return HelperService.getLabelFromId(myStatus, 'mystatus');
  }

  setPrice() {
    requestAnimationFrame(() => {
      LoaderService.show();
      this._campaignService.setInfluencerPrice(this.priceForm.value).subscribe(
        response => {
          LoaderService.hide();
          if (response.status) {
            this.priceUpdate.emit();
            this._toaster.pop('success', this._language.getTranslatedString('INFLUENCER.PRICE_UPDATED_SUCCESSFULLY'));
          } else {
            this._toaster.pop('warning', response.message);
          }
        },
        error => {
          LoaderService.hide();
          this._toaster.pop('error', this._language.getTranslatedString('MESSAGES.MSG_500'));
        }
      );
    });
  }

  onClickAcceptApplication() {
    if (this.enableAcceptRejectApplication) {
      this.applicationAcceptClick.emit(true);
    }
  }

  onClickRejectApplication() {
    if (this.enableAcceptRejectApplication) {
      this.applicationRejectClick.emit(true);
    }
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  removeFromList() {
    this.removeFromListClick.emit(this.localInfluencer);
  }

  trackByCommon(index: number, common: Common) {
    return common.id;
  }

  toggleExpansion() {
    if (this.allowExpanding) {
      this.isInfoCardExpanded = !this.isInfoCardExpanded;
    }
  }

  showInfluencerStatsModal() {
    this.showInfluencerStats = true;
    this.influencerStatsModal.show();
  }
}
