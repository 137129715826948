import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'yim-server-influencer-stats-engagement-range',
  templateUrl: './engagement-range.component.html',
  styleUrls: [
    '../../../influencer-stats/influencer-stats.component.scss',
    './engagement-range.component.scss'
  ]
})
export class EngagementRangeComponent implements OnInit {

  @Input() stats: any;
  @Input() engagementRateChartData = [];
  @Input() profileFixedEngagementRate = null;
  @Input() similarAccountEngagementRate = null;
  @Input() engagementRateChartLabels = [];
  @Input() chartReady = true;

  public engagementRateChartOptions = {
    barRoundness: 1,
    engagementRateChart: true,
    legend: {
      display: false
    },
    scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      yAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          display: false,
        },
        scaleLabel: {
          display: true,
          labelString: 'Accounts'
        }
      }],
      xAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          display: false,
        },
        scaleLabel: {
          display: true,
          labelString: 'Engagement Rate'
        }
      }]
    },
    tooltips: {
      backgroundColor: '#fff',
      titleFontColor: '#000000',
      bodyFontColor: '#646464',
      borderColor: '#646464',
      borderWidth: 1,
      filter: function (tooltipItem) {
        return tooltipItem.datasetIndex === 1;
      },
      callbacks: {
        label: (tooltipItems, data) => {
          return this.stats['engagement_rate_histogram'][tooltipItems['index']].total + ' Accounts';
        },
        title: (tooltipItems, data) => {
          if (tooltipItems.length === 0) {
            return '';
          }
          const engagementHistogram = this.stats['engagement_rate_histogram'][tooltipItems[0]['index']];
          if (tooltipItems[0].index === 0) {
            return (engagementHistogram.max * 100).toFixed(2) + '%';
          }

          if (tooltipItems[0].index === this.stats['engagement_rate_histogram'].length - 1) {
            return (engagementHistogram.min * 100).toFixed(2) + '%';
          }

          return (engagementHistogram.min * 100).toFixed(2) + '% - ' + (engagementHistogram.max * 100).toFixed(2) + '%';
        }
      },
    }
  };

  constructor() { }

  ngOnInit() {
  }

}
