import { map, mergeMap, tap, filter } from 'rxjs/operators';
import { LanguageService } from '@yim/server/services';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API, API_ADVERTISER_ENDPOINT } from '@yim/server/constance';
import { Subject, Observable, of, BehaviorSubject } from 'rxjs';

import { TOOLTIP_USE_BROWSERLANG } from './../constants';

@Injectable({
  providedIn: 'root'
})
export class TooltipService {

  private tooltipsBehav = new BehaviorSubject<{ [key: string]: string }>({});

  constructor(
    @Inject(API) private readonly api: string,
    @Inject(API_ADVERTISER_ENDPOINT) private readonly endpoint: string,
    private languageService: LanguageService,
    @Inject(TOOLTIP_USE_BROWSERLANG) private takeBrowserLang = false,
    private _http: HttpClient) {

    this.getUrlExtra().pipe(
      mergeMap(urlExtra => this._http.get(`${this.api}/${this.endpoint}/tooltip/show${urlExtra}`)),
    ).subscribe(v => {
      this.tooltipsBehav.next(v as any);
    });
  }

  private getUrlExtra(): Observable<string> {
    if (!this.takeBrowserLang) {
      let before: any = null;
      return this.languageService.values.pipe(
        map(v => v as { default_language: 'en' | 'de' }),
        map(({ default_language }) => {
          switch (default_language) {
            case 'de': return 'de-DE';
            default: return 'en-GB';
          }
        }),
        map((v) => `?lang=${v}`),
        filter(v => v !== before),
        tap(v => before = v)
      );
    }
    if (!navigator.language) { return of(''); }
    return of(`?lang=${navigator.language}`);
  }

  getTooltip(key) {
    return this.tooltipsBehav.pipe(map(v => v[key]));
  }
}
