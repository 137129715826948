import { tap } from 'rxjs/operators';
import 'rxjs/add/operator/takeWhile';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TooltipService } from '../../services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'yim-ui-tooltip',
  template: `
    <i *ngIf="tooltip" [classList]="iconClass" [tooltip]="tooltip" adaptivePosition="false"
       [placement]="placement" container="body"></i>
  `,
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit, OnDestroy {

  @Input() key;
  @Input() placement = 'right';
  @Input() iconClass = 'fa fa-info-circle';

  private sub: Subscription;

  public tooltip;
  private isAlive = true;

  constructor(private _tooltipService: TooltipService) { }

  ngOnInit() {
    this.sub = this._tooltipService.getTooltip(this.key).subscribe(v => this.tooltip = v);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    if (this.sub) { this.sub.unsubscribe(); }
  }

}
