import { API_ADVERTISER_ENDPOINT } from '@yim/server/constance';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { environment } from '../../environments/environment';
import { MessageCampaignModel, MessageInfluencerModel } from '../models';

@Injectable()
export class MessageService {

  private campaignSelected = new Subject<MessageCampaignModel>();
  private influencerSelected = new Subject<MessageInfluencerModel>();
  private announceEventHandler = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    @Inject(API_ADVERTISER_ENDPOINT) private readonly endpoint: string,
  ) { }

  getCampaignWithMessage() {
    return this.http.get<any>(`${environment.API}/${this.endpoint}/message/campaigns`);
  }

  getInfluencerWithMessage(campaignId) {
    return this.http.get<any>(`${environment.API}/${this.endpoint}/message/influencers?campaignId=${campaignId}`);
  }

  getMessages(campaignId, influencerId) {
    return this.http.get<any>(`${environment.API}/${this.endpoint}/message/conversation?campaignId=${campaignId}&influencerId=${influencerId}`);
  }

  createMessage(message) {
    return this.http.post<any>(`${environment.API}/${this.endpoint}/message/create`, message);
  }

  uploadMessageMedia(message_media) {
    const header = new HttpHeaders();
    header.append('Accept', 'multipart/form-data');
    return this.http.post<any>(`${environment.API}/${this.endpoint}/message/media`, message_media, {headers: header});
  }

  messageAnnouncement(message) {
    return this.http.post<any>(`${environment.API}/${this.endpoint}/message/announcement`, message);
  }

  selectedCampaign(campaign: MessageCampaignModel) {
    this.campaignSelected.next(campaign);
  }

  selectInfluence(influencer: MessageInfluencerModel) {
    this.influencerSelected.next(influencer);
  }

  setAnnouncementClick(status: boolean) {
    this.announceEventHandler.next(status);
  }

  getSelectedCampaign(): Observable<MessageCampaignModel> {
    return this.campaignSelected.asObservable();
  }

  getSelectedInfluencer(): Observable<MessageInfluencerModel> {
    return this.influencerSelected.asObservable();
  }

  getAnnouncementObservable(): Observable<boolean> {
    return this.announceEventHandler.asObservable();
  }

  readMessage(id) {
    return this.http.post<any>(`${environment.API}/${this.endpoint}/message/read/${id}`, null);
  }

}
