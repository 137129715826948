import { tap, mergeMap, filter, first } from 'rxjs/operators';
import { PaymentSourceModel } from './../models/payment-source.model';
import { API_ADVERTISER_ENDPOINT } from '@yim/server/constance';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LanguageService } from '@yim/server/services';
import { ToasterService } from 'angular2-toaster';
import * as _ from 'lodash';
import { Subject, BehaviorSubject, Observable, of } from 'rxjs';

import { LoaderService } from '../../../projects/ui/src/lib/services/loader.service';
import { environment } from '../../environments/environment';
import { fromPromise } from 'rxjs/internal-compatibility';

@Injectable()
export class ProfileService {

  profile: any = {};
  profile_subject = new Subject<any>();
  private readonly paymentSourceBehav = new BehaviorSubject<PaymentSourceModel | null>(null);

  constructor(private http: HttpClient,
              private _toaster: ToasterService,
              private _language: LanguageService,
              @Inject(API_ADVERTISER_ENDPOINT) private readonly endpoint: string
  ) { }

  getUserProfileData() {
    return this.http.get<any>(`${environment.API}/${this.endpoint}/profile/data`);
  }

  setUserProfileData(postValues) {
    return this.http.post<any>(`${environment.API}/${this.endpoint}/profile/data`, postValues);
  }

  setProfileImage(postValues) {
    const header = new HttpHeaders();
    header.append('Accept', 'multipart/form-data');
    return this.http.post<any>(`${environment.API}/${this.endpoint}/profile/image`, postValues, {headers: header});
  }

  deleteProfileImage() {
    return this.http.delete<any>(`${environment.API}/${this.endpoint}/profile/image`);
  }

  updateProfileData(postValues) {
    requestAnimationFrame(() => {
      LoaderService.show();
      this.setUserProfileData(postValues).subscribe(
        (response) => {
          LoaderService.hide();
          if (response['status']) {
            _.merge(this.profile['advertiser'], postValues);
            this.profile_subject.next(this.profile);
            this._toaster.pop('success', this._language.getTranslatedString('MESSAGES.UPDATE_TITLE'));
          } else {
            this._toaster.pop('error', this._language.getTranslatedString('MESSAGES.ERROR_TITLE'));
          }
        }, error => {
          LoaderService.hide();
          // this.profile_subject.next(this.profile);
          this._toaster.pop('error', this._language.getTranslatedString('MESSAGES.MSG_500'));
        });
    });
  }

  getProfileData() {
    requestAnimationFrame(() => {
      LoaderService.show();
      this.getUserProfileData().subscribe((response) => {
        LoaderService.hide();
        this.profile['advertiser'] = response;
        this.profile_subject.next(this.profile);
      }, error => {
        LoaderService.hide();
        this._toaster.pop('error', this._language.getTranslatedString('MESSAGES.MSG_500'));
      });
    });
  }

  updateProfileImage(profileImage) {
    requestAnimationFrame(() => {
      LoaderService.show();
      this.setProfileImage(profileImage).subscribe((response) => {
        LoaderService.hide();
        if (response['picture']) {
          this.profile['advertiser']['picture'] = response['picture'];
          this.profile_subject.next({...this.profile, updateProfileImage: true});
          this._toaster.pop('success', this._language.getTranslatedString('MESSAGES.IMAGE_UPDATE_SUCCESS'));
        } else {
          this._toaster.pop('error', this._language.getTranslatedString('MESSAGES.ERROR_TITLE'));
        }
      }, (error) => {
        LoaderService.hide();
        this._toaster.pop('error', this._language.getTranslatedString('MESSAGES.MSG_500'));
      });
    });
  }

  removeProfileImage() {
    requestAnimationFrame(() => {
      LoaderService.show();
      this.deleteProfileImage().subscribe((response) => {
        LoaderService.hide();
        if (response['status']) {
          this.profile['advertiser']['picture'] = null;
          this.profile_subject.next({...this.profile, updateProfileImage: true});
          this._toaster.pop('success', this._language.getTranslatedString('MESSAGES.IMAGE_REMOVE_SUCCESS'));
        } else {
          this._toaster.pop('error', this._language.getTranslatedString('MESSAGES.ERROR_TITLE'));
        }
      }, (error) => {
        LoaderService.hide();
        this._toaster.pop('error', this._language.getTranslatedString('MESSAGES.MSG_500'));
      });
    });
  }

  getPayments() {
    return this.http.get<{
      [key in '' | '1' | '2' | '3']?: string
      // "": "No payment",
      // "1": "Sepa"
  }>(`${environment.API}/${this.endpoint}/profile/payment`);
  }

  setSource(postValues) {
    return this.http.post<any>(`${environment.API}/${this.endpoint}/profile/source`, postValues);
  }

  getSource() {
    return fromPromise(new Promise<PaymentSourceModel>((resolve) => {
      this.refreshSource().subscribe(v => {
        resolve(v);
      });
    }));
  }

  refreshSource() {
    return this.http.get<PaymentSourceModel>(`${environment.API}/${this.endpoint}/profile/source`).pipe(
      tap(v => this.paymentSourceBehav.next(v)),
      mergeMap(v => this.paymentSourceBehav)
    );
  }

  deleteSource() {
    return this.http.delete<any>(`${environment.API}/${this.endpoint}/profile/source`);
  }

  getClientSecret() {
    return this.http.get<any>(`${environment.API}/${this.endpoint}/profile/cardsecret`);
  }

  setCard(postValues) {
    return this.http.post<any>(`${environment.API}/${this.endpoint}/profile/card`, postValues);
  }

  deleteCard() {
    return this.http.delete<any>(`${environment.API}/${this.endpoint}/profile/card`);
  }

  emptyProfile() {
    this.profile = {};
  }
}
