import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'yim-server-influencer-stats-posts',
  templateUrl: './posts.component.html',
  styleUrls: [
    '../../../influencer-stats/influencer-stats.component.scss',
    './posts.component.scss'
  ]
})
export class PostsComponent implements OnInit {

  @Input() postListToShow = [];
  @Input() postShowing: 'popular' | 'sponsored';

  @Output() changePostToShow = new EventEmitter<'popular' | 'sponsored'>();

  constructor() { }

  ngOnInit() {
  }

}
