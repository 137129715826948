import { Component } from '@angular/core';

@Component({
  selector: 'yimify-app-header',
  templateUrl: './yimify-app-header.component.html',
  styleUrls: ['./yimify-app-header.component.scss']
})
export class YimifyAppHeaderComponent {

  constructor() {
  }

}
