import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({name: 'yimifyDate'})
export class YimifyDatePipe implements PipeTransform {
  transform(date: number, format = 'DD.MM.YYYY') {
    let m: moment.Moment;
    if (('' + date).length === 10) {
      m = moment(new Date(date * 1000));
    } else {
      m = moment.unix(date);
    }
    return m.format(format);
  }
}

