import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'yim-server-influencer-stats-age-gender-split',
  templateUrl: './age-gender-split.component.html',
  styleUrls: [
    '../../../influencer-stats/influencer-stats.component.scss',
    './age-gender-split.component.scss'
  ]
})
export class AgeGenderSplitComponent implements OnInit {

  @Input() stats: { [key: string]: any; };
  @Input() audienceSplitChartData = [];
  @Input() audienceSplitChartLabels = [];
  @Input() chartReady = true;

  public audienceSplitChartColors = [{backgroundColor: '#E94683'}, {backgroundColor: '#3B657A'}];

  public audienceSplitChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: false,
    barRoundness: 1,
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        },
        barPercentage: 0.7,
      }],
      yAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          display: false,
          stepSize: 10,
        },
        afterBuildTicks: function (chartObj) {
          let maxValue = 0;
          if (chartObj.chart.config && chartObj.chart.config.data && chartObj.chart.config.data.datasets) {
            chartObj.chart.config.data.datasets.forEach(dataset => {
              if (dataset && dataset.data) {
                dataset.data.forEach(value => {
                  if (+value > maxValue) {
                    maxValue = value;
                  }
                });
              }
            });
          }
          chartObj.options.ticks.max = parseInt(maxValue.toString(), 10) + 10;
        }
      }]
    },
    tooltips: {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: function (tooltipItems, data) {
          const dataset = data['datasets'][tooltipItems['datasetIndex']];
          return dataset['label'] + ' ' + dataset['data'][tooltipItems['index']] + ' %';
        }
      }
    },
    legend: {
      display: false
    },
    animation: {
      onComplete: function () {
        const ctx = this.chart.ctx;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        const chart = this;
        const datasets = this.config.data.datasets;
        datasets.forEach(function (dataset: Array<any>, i: number) {
          ctx.font = '10px Arial';
          ctx.fillStyle = 'Black';
          chart.getDatasetMeta(i).data.forEach(function (p: any, j: any) {
            ctx.fillText(datasets[i].data[j] + '%', p._model.x, p._model.y - 5);
          });

        });
      }
    }
  };

  constructor() { }

  ngOnInit() {
  }

}
