import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/catch';

import { UserService } from '../services';

/*
 Interceptor for every api request to append Authorization to header
*/

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private _user: UserService, private _router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    request = request.clone({
      setHeaders: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    return next.handle(request).catch((error) => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._user.logout();
          this._router.navigate(['/login']);
        }
      }
      return throwError(error);
    });
  }

}
