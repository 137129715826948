export const environment = {
  production: true,
  API: 'https://api.demo.yimify.com/v1',
  websocket: 'wss://api.bistduanders.de:3000',
  bugsnagAPIKey: '9a30a2a3c8eba4288183a56bb74a1735',
  Default_language: 'de', // 'de'
  advertiserEndpoint: 'customer',
  searchInfluencerItemsPerRequest: 15,
  stripeKey: 'pk_test_4KdXcNvqill4Cgc4nml5TQgR',
  BLOG: 'https://www.yimify.com/wp-json/wp/v2/posts',
  CAMPAIGN_DETAIL_MEDIA_SLIDER_INTERVAL: 2000,
  FILE_PREFIX_URL: 'https://api.demo.yimify.com/files',
  CHATRA_ID: ''
};

