import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CampaignService, LanguageService } from '@yim/server/services';
import { LoaderService } from '@yim/ui/services';
import { ToasterService } from 'angular2-toaster';

import { UserService } from '../../../services';
import { SidebarService } from './../../../containers/default-layout/sidebar/services/sidebar.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: [
    '../general.user.component.scss',
    './login.component.scss'
  ]
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public showSuccessMessage: boolean = false;
  public api_error = {};

  constructor(_fb: FormBuilder,
              private _router: Router,
              private _user_service: UserService,
              private _lang_service: LanguageService,
              private _campaign_service: CampaignService,
              private _toaster: ToasterService,
              private sidebarService: SidebarService) {
    this.loginForm = _fb.group({
      'username': ['', Validators.required],
      'password': ['', Validators.required]
    });
  }

  login(user_values) {
    this.api_error = {};
    requestAnimationFrame(() => {
      LoaderService.show();
      this._user_service.login(user_values).subscribe(res => {
          if (res['access_token']) {
            localStorage.setItem('authenticated', '1');
            localStorage.setItem('user', JSON.stringify(res));
            localStorage.setItem('token', res['access_token']);
            this._user_service.tokenSubject.next(res['access_token']);
            if (res['language'] === 'de-DE') {
              this._lang_service.setLanguage('de');
            } else {
              this._lang_service.setLanguage('en');
            }
            this.showSuccessMessage = true;
            this.getAllRequiredData();
          }
          this.sidebarService.refresh();
        },
        error => {
          LoaderService.hide();
          if (error['error']) {
            this.api_error = error['error']['messages'] || {};
          }
        });
    });
  }

  async getAllRequiredData() {
    if (localStorage.getItem('authenticated') !== null && localStorage.getItem('authenticated') === '1') {
      do {
        const results = await this._campaign_service.getAllRequiredData();
        if (Array.isArray(results)) {
          localStorage.setItem('types', JSON.stringify(results[0]));
          localStorage.setItem('payments', JSON.stringify(results[1]));
          localStorage.setItem('channels', JSON.stringify(results[2]));
          localStorage.setItem('status', JSON.stringify(results[3]));
          localStorage.setItem('mystatus', JSON.stringify(results[4]));
          localStorage.setItem('overviewtabs', JSON.stringify(results[5]));
        }
      } while (this.checkRequiredDate());
    }
    await this._router.navigate(['/dashboard']);
    LoaderService.hide();
  }

  checkRequiredDate() {
    const types = localStorage.getItem('types');
    const payments = localStorage.getItem('payments');
    const channels = localStorage.getItem('channels');
    const status = localStorage.getItem('status');
    const myStatus = localStorage.getItem('mystatus');
    const overviewTabs = localStorage.getItem('overviewtabs');
    for (const valToCheck in [types, payments, channels, status, myStatus, overviewTabs]) {
      if (valToCheck === undefined || valToCheck === null) {
        return true;
      }
    }
    return false;
  }

  ngOnInit(): void {
    this._toaster.clear();
  }

}
