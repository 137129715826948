import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-drop-zone',
  templateUrl: './drop-zone.component.html',
  styleUrls: ['./drop-zone.component.scss']
})
export class DropZoneComponent {
  @ViewChild('mediaInput', { static: true }) mediaInput: ElementRef;

  @Input() allowedFileType = ['jpg', 'png'];
  @Input() invalidFileText = '';
  @Input() placeHolderText = '';
  @Input() onDragText = '';
  @Input() allowMultiple = false;

  @Output() fileHandle = new EventEmitter();
  @Output() fileSelectionInitiated = new EventEmitter();

  public fileDragOver: boolean = false;

  constructor(private _toaster: ToasterService) {

  }

  onMediaHandle(files) {
    this.fileSelectionInitiated.emit(files.length);
    for (let i = 0; i < files.length; i++) {
      const fileToUpload = files.item(i);
      if (fileToUpload) {
        if (fileToUpload['name'] !== undefined || fileToUpload['name'] !== null) {
          const fileExt = fileToUpload['name'].split('.').pop();
          if (this.allowedFileType.includes(fileExt.toLowerCase())) {
            this.fileHandle.emit(fileToUpload);
          } else {
            this.fileHandle.emit(null);
            this._toaster.pop('warning', this.invalidFileText);
          }
        }
      }
    }
    this.mediaInput.nativeElement.value = '';
  }

}
