import { Subscription } from 'rxjs';
import { SidebarService } from './services/sidebar.service';
import { Component, OnDestroy } from '@angular/core';
import { INavData } from '@coreui/angular';

const newLocal = true;

@Component({
  selector: 'yimify-app-sidebar',
  templateUrl: './yimify-app-sidebar.component.html',
  styleUrls: ['./yimify-app-sidebar.component.scss']
})

export class YimifyAppSideBarComponent implements OnDestroy {

  public navItems: INavData[];
  public sidebarMinimized = newLocal;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;

  private navSub: Subscription;

  constructor(navigationService: SidebarService) {

    this.navSub = navigationService.NavigationItems.subscribe(v => this.navItems = v);

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });

    this.changes.observe(<Element>this.element, {
      attributes: true
    });
  }

  ngOnDestroy() {
    this.element.classList.remove('header-fixed', 'sidebar-fixed', 'sidebar-lg-show');
    this.changes.disconnect();
    if (this.navSub) { this.navSub.unsubscribe(); }
  }
}
