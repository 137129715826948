import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DefaultLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { PaymentSuccessComponent } from './components';
import { ChangePasswordComponent, ForgotPasswordComponent, LoginComponent, ResetPasswordComponent } from './views/user';

import { AuthGuard } from './guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'page-not-found',
    component: P404Component
  },
  {
    path: '500',
    component: P500Component
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent
  },
  {
    path: 'payments/success',
    component: PaymentSuccessComponent
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: './views/profile/profile.module#ProfileModule'
      },
      {
        path: 'change-password',
        canActivate: [AuthGuard],
        component: ChangePasswordComponent
      },
      {
        path: 'campaign',
        canActivate: [AuthGuard],
        loadChildren: './views/campaign/campaign.module#CampaignModule'
      },
      {
        path: 'messenger',
        canActivate: [AuthGuard],
        loadChildren: './messenger/messenger.module#MessengerModule'
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: './views/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'news',
        canActivate: [AuthGuard],
        loadChildren: './views/news/news.module#NewsModule'
      },
      {
        path: 'influencer',
        canActivate: [AuthGuard],
        loadChildren: './views/influencer/influencer.module#InfluencerModule'
      },
      {
        path: 'payments',
        canActivate: [AuthGuard],
        loadChildren: './views/payments/payments.module#PaymentsModule'
      },
      {
        path: 'applications',
        canActivate: [AuthGuard],
        loadChildren: './views/applications/applications.module#ApplicationsModule'
      },
      {
        path: 'blog',
        canActivate: [AuthGuard],
        loadChildren: './views/blog/blog.module#BlogModule'
      },
      {
        path: 'history',
        canActivate: [AuthGuard],
        loadChildren: './views/history/history.module#HistoryModule'
      },
      {
        path: 'packages',
        canActivate: [AuthGuard],
        loadChildren: './views/packages/packages.module#PackagesModule'
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'page-not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor() {
  }
}
