import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoaderService } from '@yim/ui/services';

import { UserService } from '../../../services';
import { ConfirmPasswordValidation } from '../../../validators/confirm-password.validator';

@Component({
  templateUrl: 'change_password.component.html',
  styleUrls: ['./change_password.component.scss']
})
export class ChangePasswordComponent {

  public changePasswordForm: FormGroup;
  public isPasswordChanged = false;
  public api_error = {};

  constructor(private _router: Router,
              private _fb: FormBuilder,
              private _user_service: UserService) {

    this.changePasswordForm = _fb.group({
        'password': ['', [Validators.required, Validators.minLength(6)]],
        'passwordRepeat': ['', [Validators.required, Validators.minLength(6)]]
      },
      {
        validator: ConfirmPasswordValidation.MatchPassword
      });
  }

  changePassword(user_values) {
    this.api_error = {};
    requestAnimationFrame(() => {
      LoaderService.show();
      this._user_service.changePassword(user_values).subscribe(
        res => {
          LoaderService.hide();
          if (res['status'] === true) {
            this.api_error = {};
            this.isPasswordChanged = true;
            setTimeout(() => {
              this._router.navigate(['/']);
            }, 1500);
          } else {
            this._router.navigate(['/login']);
          }
        },
        error => {
          LoaderService.hide();
          this.api_error = error.error.messages;
        });
    });
  }

}
