import { Component, OnInit } from '@angular/core';
import { PaymentService } from '../../services';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    setTimeout(() => {
      // @ts-ignore
      window.top.postMessage(PaymentService.PAYMENT_3DS_AUTHENTICATION_COMPLETE);
    }, 5000);
  }

}
