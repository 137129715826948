import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'yim-server-influencer-stats-audience-details',
  templateUrl: './audience-details.component.html',
  styleUrls: [
    '../../../influencer-stats/influencer-stats.component.scss',
    './audience-details.component.scss'
  ]
})
export class AudienceDetailsComponent implements OnInit {

  @Input() stats: any;

  constructor() { }

  ngOnInit() {
  }

}
