import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoaderService } from '@yim/ui/services';

import { UserService } from '../../../services';

@Component({
  templateUrl: 'forgot_password.component.html',
  styleUrls: [
    '../general.user.component.scss',
    './forgot_password.component.scss'
  ]
})
export class ForgotPasswordComponent {

  public forgotPasswordForm: FormGroup;
  public isSendEmail = false;
  public api_error = {};

  constructor(public _router: Router, public fb: FormBuilder, public _user_service: UserService) {

    this.forgotPasswordForm = fb.group({
      'email': ['', [Validators.required, Validators.email]]
    });
  }

  forgotPassword(user_values) {
    this.api_error = {};
    requestAnimationFrame(() => {
      LoaderService.show();
      this._user_service.forgotPassword(user_values).subscribe(res => {
          LoaderService.hide();
          if (res['status'] === true) {
            this.isSendEmail = true;
          }
        },
        error => {
          LoaderService.hide();
          if (error['error']['status'] === false) {
            this.api_error['email'] = ['email does not exist.'];
          }
          setTimeout(() => {
            this.api_error = {};
          }, 5000);
        });
    });
  }
}
