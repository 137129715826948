import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { InfluencerService } from '@yim/server/services';
import { LoaderService } from '@yim/ui/services';
import { ToasterService } from 'angular2-toaster';
import * as Chart from 'chart.js';
import * as _ from 'lodash';
import * as moment from 'moment';
import { takeWhile } from 'rxjs/operators';

import { Channel } from './../../models/channel';

declare var WordCloud: any;

@Component({
  selector: 'yim-server-influencer-stats',
  templateUrl: './influencer-stats.component.html',
  styleUrls: ['./influencer-stats.component.scss']
})
export class InfluencerStatsComponent implements OnInit, OnDestroy {

  // todo add model accordingly

  @Input() influencerId: number = null;
  @Output() hideClick = new EventEmitter();
  @Input() isDemo = false;
  @Input() channel = Channel.Instagram;
  public openUsingPopUp = true;

  public stats: any;
  public hashtags: any[] = [];
  public mentions: any[] = [];

  public statHistoryLabels = [];

  public likesStatHistoryData = [];
  public followersStatHistoryData = [];
  public followingStatHistoryData = [];

  public locationByCountries = [];
  public locationByCities = [];

  public audienceSplitChartLabels = [];
  public audienceSplitChartData = [];

  public engagementSpreadChartLabels = [];
  public engagementSpreadChartData = [];

  public engagementRateChartLabels = [];
  public engagementRateChartData = [];

  private engagementRateChartDataMedian = -1;
  private engagementRateChartDataProfile = -1;

  public profileFixedEngagementRate = null;
  public similarAccountEngagementRate = null;

  public postShowing: 'popular' | 'sponsored' = 'popular';
  public postListToShow = [];

  public tagCloudData = [];

  public followerIncrement = 0;
  public followingIncrement = 0;
  public likesIncrement = 0;

  private alive = true;

  constructor(private _influencerService: InfluencerService,
              private _loader: LoaderService,
              private _activatedRoute: ActivatedRoute,
              private _toasterService: ToasterService) {
    this.roundedTopBarChartInit();

    this._activatedRoute.params
      .pipe(takeWhile(() => this.alive))
      .subscribe((params: Params) => {
        this.influencerId = +params['influencerid'];
        if (this.influencerId) {
          this.openUsingPopUp = false;
        }
      });
  }

  ngOnInit() {
    if (this.influencerId) {
      this.getInfluencerStatsData();
    }
  }

  getInfluencerStatsData() {
    requestAnimationFrame(() => {
      LoaderService.show();
      this._influencerService.getInfluencerStats(this.influencerId, this.channel)
        .subscribe(
          (response: any) => {
            if (response.hasOwnProperty('error') && response.hasOwnProperty('messages')) {
              this._toasterService.pop('error', response.messages);
              this.hideClicked();
            } else {
              this.stats = response;
              this.setStatsData();
            }
            LoaderService.hide();
          },
          (error) => {
            LoaderService.hide();
          }
        );
    });
  }

  setStatsData() {
    if (this.stats) {
      this.profileFixedEngagementRate = (parseFloat(String(this.stats['engagementrate'])) * 100).toFixed(2);
      this.stats['influencer_interests'] = _.map(this.stats['influencer_interests'], (value) => value.trim());
      this.stats['audience_credibility'] = parseFloat(String(this.stats['audience_credibility'] * 100)).toFixed(2);
      this.stats['notable_engagements'] = parseFloat(String(this.stats['notable_engagements'] * 100)).toFixed(2);
      this.stats['paid_post_performance'] = parseFloat(String(this.stats['paid_post_performance'] * 100)).toFixed(2);
      this.stats['notable_followers_ratio'] = parseFloat(String(this.stats['notable_followers_ratio'] * 100)).toFixed(2);

      Object.keys(this.stats['hashtags']).forEach(
        (val, key) => {
          this.hashtags.push({
            name: '#' + val,
            percentage: parseFloat(String(this.stats['hashtags'][val] * 100)).toFixed(2)
          });
        }
      );
      Object.keys(this.stats['mentions']).forEach(
        (val, key) => {
          this.mentions.push({
            name: '@' + val,
            percentage: parseFloat(String(this.stats['mentions'][val] * 100)).toFixed(2)
          });
        }
      );

      if (this.stats['stat_history']) {
        this.stats['stat_history'] = this.stats['stat_history'].sort((first, second) => {
          return moment(first['month']).unix() - moment(second['month']).unix();
        });

        this.stats['stat_history'] = this.stats['stat_history'].slice(this.stats['stat_history'].length - 5);

        this.statHistoryLabels = _.map(this.stats['stat_history'], (history) => {
          return moment(history.month).format('MMM');
        });

        this.likesStatHistoryData = _.map(this.stats['stat_history'], (history) => history.avg_likes);

        this.followersStatHistoryData = _.map(this.stats['stat_history'], (history) => history.followers);

        this.followingStatHistoryData = _.map(this.stats['stat_history'], (history) => history.following);

        const length = this.stats['stat_history'].length;
        const thisMonth = this.stats['stat_history'][length - 1];
        const prevMonth = this.stats['stat_history'][length - ((length > 1) ? 2 : 1)];

        this.followerIncrement = ((thisMonth['followers'] - prevMonth['followers']) * 100) / thisMonth['followers'];
        this.followingIncrement = ((thisMonth['following'] - prevMonth['following']) * 100) / thisMonth['following'];
        this.likesIncrement = ((thisMonth['avg_likes'] - prevMonth['avg_likes']) * 100) / thisMonth['avg_likes'];

        if (this.followerIncrement !== this.followerIncrement) {
          this.followerIncrement = 0;
        }

        if (this.followingIncrement !== this.followingIncrement) {
          this.followingIncrement = 0;
        }

        if (this.likesIncrement !== this.likesIncrement) {
          this.likesIncrement = 0;
        }
      }
      this.locationByCountries = _.map(this.stats['location_by_country'][0], (val, key) => ({key: key, value: val}));
      this.locationByCities = this.stats['location_by_cities'][0];

      this.audienceSplitChartLabels = _.keys(this.stats['age_split']);
      const maleArray = _.map(this.stats['age_split'], ages => (ages['MALE'] * 100).toFixed(ages['MALE'] < 0.10 ? 1 : 0));
      const femaleArray = _.map(this.stats['age_split'], ages => (ages['FEMALE'] * 100).toFixed(ages['FEMALE'] < 0.10 ? 1 : 0));
      this.audienceSplitChartData = [
        {data: femaleArray, label: 'Female'},
        {data: maleArray, label: 'Male'}
      ];

      this.stats['engagement_spread'] = this.stats['engagement_spread'].sort((firstPost, secondPost) => {
        return moment(firstPost.created).unix() - moment(secondPost.created).unix();
      });

      const likeArray = _.map(this.stats['engagement_spread'], (post) => post.stat.likes);
      const likeMin = _.max(likeArray) * 0.10; // if min value is 0 than we take 10 % of highest value
      const commentArray = _.map(this.stats['engagement_spread'], (post) => (post.stat.comments > likeMin) ? post.stat.comments : likeMin);
      this.engagementSpreadChartLabels =
        _.map(this.stats['engagement_spread'], (post) => moment(post.created));
      this.engagementSpreadChartData = [
        {data: commentArray, label: 'Comments'},
        {data: likeArray, label: 'Likes'}
      ];

      this.engagementRateChartLabels = _.map(this.stats['engagement_rate_histogram'], (item, index) => index);
      const engagementRateData = _.map(this.stats['engagement_rate_histogram'], (rateInfo) => rateInfo.total);
      const engagementRateDataLine = _.map(this.stats['engagement_rate_histogram'], (rateInfo) => rateInfo.total);

      const engagementRatePointRadius = _.times(this.stats['engagement_rate_histogram'].length, _.constant(0));
      const engagementRatePointHoverRadius = engagementRatePointRadius;

      this.engagementRateChartDataMedian = _.findIndex(this.stats['engagement_rate_histogram'], (rateInfo) => rateInfo.median);

      this.engagementRateChartDataProfile = _.findIndex(this.stats['engagement_rate_histogram'], (rateInfo) => {
        return (rateInfo.min <= this.stats['engagementrate'] && rateInfo.max > this.stats['engagementrate']);
      });

      if (this.engagementRateChartDataMedian !== -1) {
        engagementRatePointRadius[this.engagementRateChartDataMedian] = 10;
        const engagementRateHistogramObj = this.stats['engagement_rate_histogram'][this.engagementRateChartDataMedian];
        const engagementRate = (engagementRateHistogramObj.min + engagementRateHistogramObj.min) / 2;

        this.similarAccountEngagementRate = (parseFloat(String(engagementRate)) * 100).toFixed(2);
      }

      if (this.engagementRateChartDataProfile !== -1) {
        engagementRatePointHoverRadius[this.engagementRateChartDataProfile] = 10;
      }

      const engagementRateBarColor = _.map(this.stats['engagement_rate_histogram'], (rateInfo, index) => {
        if (this.engagementRateChartDataProfile === index || this.engagementRateChartDataMedian === index) {
          return '#E94683';
        } else {
          return '#C6DBE5';
        }
      });

      this.engagementRateChartData = [
        {
          type: 'line',
          data: engagementRateDataLine,
          label: '',
          borderWidth: 1,
          pointRadius: engagementRatePointRadius,
          pointHoverRadius: engagementRatePointHoverRadius,
          pointBackgroundColor: '#3B657A',
          fill: false,
          showLine: false
        },
        {
          data: engagementRateData,
          label: 'Engagement Rate',
          backgroundColor: engagementRateBarColor
        }
      ];

      this.changePostToShow(this.postShowing);

      this.tagCloudData = _(this.stats['relevant_tags'])
        .map((tag) => {
          return [tag.tag, tag.freq * 2];
        })
        .take(15)
        .value();

      setTimeout(() => {
        WordCloud.minFontSize = 12;
        WordCloud(
          document.getElementById('tagcloud'),
          {
            list: this.tagCloudData,
            rotateRatio: 0,
            color: function () {
              return '#3B657A';
            },
            shuffle: true
          });
      }, 1000);

      Chart.pluginService.register({
        afterUpdate: (chart) => {
          if (chart.config.options.engagementRateChart) {
            const metaKey = Object.keys(chart.config.data.datasets[0]._meta)[0];
            const dataLength = chart.config.data.datasets[0]._meta[metaKey].data.length;

            for (let i = 0; i < dataLength; i++) {
              // setting all point style to default
              chart.config.data.datasets[0]._meta[metaKey].data[i]._model.pointStyle = 'circle';
            }

            if (this.engagementRateChartDataMedian >= 0) {
              chart.config.data.datasets[0]._meta[metaKey].data[this.engagementRateChartDataMedian]._model.radius = 10;
            }
            if (this.engagementRateChartDataProfile >= 0) {
              const profilePicture = new Image();
              profilePicture.src = this.stats['profile_picture'];
              profilePicture.height = profilePicture.width = 20;
              chart.config.data.datasets[0]._meta[metaKey].data[this.engagementRateChartDataProfile]._model.pointStyle = profilePicture;
            }
          }
        }
      });
    }
  }

  changePostToShow(type: 'popular' | 'sponsored' | string) {
    switch (type) {
      case 'popular': case 'sponsored':
        this.postListToShow = this.stats[type + '_posts'];
        this.postShowing = type;
        break;
      default:
        this.postListToShow = [];
    }
  }

  roundedTopBarChartInit() {
    Chart.helpers.drawRoundedTopRectangle = function (ctx, x, y, width, height, radius) {
      ctx.beginPath();
      ctx.moveTo(x + radius, y);
      ctx.lineTo(x + width - radius, y);
      ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
      ctx.lineTo(x + width, y + height);
      ctx.lineTo(x, y + height);
      ctx.lineTo(x, y + radius);
      ctx.quadraticCurveTo(x, y, x + radius, y);
      ctx.closePath();
    };

    Chart.elements.RoundedTopRectangle = Chart.elements.Rectangle.extend({
      draw: function () {

        let lastVisible = 0;
        for (let findLast = 0, findLastTo = this._chart.data.datasets.length; findLast < findLastTo; findLast++) {
          if (!this._chart.getDatasetMeta(findLast).hidden) {
            lastVisible = findLast;
          }
        }
        const isLastIndex = this._datasetIndex !== lastVisible;
        const isStackBar = (this._chart.config.options.roundedStackBar === true && isLastIndex);

        const ctx = this._chart.ctx;
        const vm = this._view;
        let left, right, top, bottom, signX, signY, borderSkipped;
        let borderWidth = vm.borderWidth;

        if (!vm.horizontal) {
          left = vm.x - vm.width / 2;
          right = vm.x + vm.width / 2;
          top = vm.y;
          bottom = vm.base;
          signX = 1;
          signY = bottom > top ? 1 : -1;
          borderSkipped = vm.borderSkipped || 'bottom';
        } else {
          left = vm.base;
          right = vm.x;
          top = vm.y - vm.height / 2;
          bottom = vm.y + vm.height / 2;
          signX = right > left ? 1 : -1;
          signY = 1;
          borderSkipped = vm.borderSkipped || 'left';
        }

        if (borderWidth && !(isStackBar)) {
          const barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
          borderWidth = borderWidth > barSize ? barSize : borderWidth;
          const halfStroke = borderWidth / 2;
          const borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
          const borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
          const borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
          const borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);

          if (borderLeft !== borderRight) {
            top = borderTop;
            bottom = borderBottom;
          }
          if (borderTop !== borderBottom) {
            left = borderLeft;
            right = borderRight;
          }
        }
        const barWidth = Math.abs(left - right);
        const roundness = this._chart.config.options.barRoundness || 0.5;
        let radius = barWidth * roundness * 0.5;
        // to not show radius in stacked bars there must be roundedStackBar set to true in options
        if (isStackBar) {
          radius = 0;
        }
        const prevTop = top;

        top = prevTop + radius;
        let barRadius = top - prevTop;

        if (isStackBar) {
          barRadius = 0;
        }
        ctx.beginPath();
        ctx.fillStyle = vm.backgroundColor;
        ctx.strokeStyle = vm.borderColor;
        ctx.lineWidth = borderWidth;

        Chart.helpers.drawRoundedTopRectangle(ctx, left, (top - barRadius + 1), barWidth, bottom - prevTop, barRadius);

        ctx.fill();
        if (borderWidth) {
          ctx.stroke();
        }
        top = prevTop;
      },
    });

    Chart.defaults.roundedBar = Chart.helpers.clone(Chart.defaults.bar);

    Chart.controllers.roundedBar = Chart.controllers.bar.extend({
      dataElementType: Chart.elements.RoundedTopRectangle
    });
  }

  chartClicked(e: any): void {
    if (e.active.length > 0) {
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);
      if (activePoints.length > 0) {
        const clickedElementIndex = activePoints[0]._index;
        const url = this.stats['engagement_spread'][clickedElementIndex]['link'];
        window.open(url, '_blank');
      }
    }
  }

  hideClicked() {
    this.hideClick.emit(true);
  }

  ngOnDestroy() {
    this.alive = false;
  }
}

