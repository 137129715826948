import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { API, API_ADVERTISER_ENDPOINT } from './../constance';
import { Common } from '../models';

@Injectable({ providedIn: 'root' })
export class ListsService {

  private listArray: Common[] = [];

  constructor(
    @Inject(API) private readonly api: string,
    @Inject(API_ADVERTISER_ENDPOINT) private readonly endpoint: string,
    private http: HttpClient) {
  }

  fetchListArray() {
    this.getLists().subscribe(
      (response) => {
        this.listArray = response;
      });
  }

  getListArray() {
    return this.listArray;
  }

  getLists() {
    return this.http.get<any>(`${this.api}/${this.endpoint}/list/getlists`);
  }

  addList(formData) {
    return this.http.post<any>(`${this.api}/${this.endpoint}/list/addlist`, formData);
  }

  getListItems(listID) {
    return this.http.get<any>(`${this.api}/${this.endpoint}/list/getitems?id=${listID}`);
  }

  addToList(listID, influencerId, socialId) {
    const postValues = {list: listID, influencerId: influencerId, socialId: socialId};
    return this.http.post<any>(`${this.api}/${this.endpoint}/list/addtolist`, postValues);
  }

  deleteList(listId) {
    return this.http.post<any>(`${this.api}/${this.endpoint}/list/deletelist?id=${listId}`, {});
  }

  removeFromList(listId, influencerId, socialId) {
    const postValues = {list: listId, influencerId: influencerId, socialId: socialId};
    return this.http.post<any>(`${this.api}/${this.endpoint}/list/removefromlist`, postValues);
  }
}
