import { NumberPipe } from '@yim/ui/pipes/number.pipe';
import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

@Component({
  selector: 'yim-server-influencer-stats-engagement-spread',
  templateUrl: './engagement-spread.component.html',
  styleUrls: [
    '../../../influencer-stats/influencer-stats.component.scss',
    './engagement-spread.component.scss'
  ]
})
export class EngagementSpreadComponent implements OnInit {

  @Input() engagementSpreadChartData = [];
  @Input() engagementSpreadChartLabels = [];
  @Input() stats: any;
  @Input() chartReady = true;

  @Output() chartClicked = new EventEmitter<any>();

  public colors: any[] = [{backgroundColor: '#3B657A'}, {backgroundColor: '#E94683'}];

  public engagementSpreadChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: true,
    barRoundness: 1,
    roundedStackBar: true,
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        },
        barPercentage: 0.7,
        stacked: true,
        ticks: {
          source: 'data',
          callback: (label, index) => {
            const curMonth = this.engagementSpreadChartLabels[index];
            if (index === 0) {
              return curMonth.format('MMM');
            }
            const prevMonth = this.engagementSpreadChartLabels[index - 1];
            return curMonth.isSame(prevMonth, 'month') ? null : curMonth.format('MMM');
          }
        }
      }],
      yAxes: [{
        ticks: { callback: NumberPipe.transform },
        gridLines: {
          display: false
        },
        stacked: true
      }]
    },
    legend: {
      display: false
    },
    tooltips: {
      callbacks: {
        label: (tooltipItems, data) => {
          const label = data.datasets[tooltipItems.datasetIndex].label;
          if (tooltipItems.datasetIndex === 0) {
            return label + ':' + this.stats['engagement_spread'][tooltipItems.index].stat.comments;
          }
          return label + ':' + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index];
        }
      }
    }
  };

  constructor() { }

  ngOnInit() {
  }

}
