import { Component, Input, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { VgAPI } from 'videogular2/compiled/core';

@Component({
  selector: 'video-popup',
  templateUrl: './video-popup.component.html',
  styleUrls: ['./video-popup.component.scss']
})

export class VideoPopupComponent {
  @ViewChild(ModalDirective, { static: false }) public videoModel: ModalDirective;

  @Input() videoUrl: string;
  @Input() fileType: string;
  @Input() fileName: string;

  public showPopUp: boolean = true;
  public vgApi: VgAPI;
  public defaultapi: VgAPI;

  constructor() {
  }

  onPlayerReady(api: VgAPI) {
    this.vgApi = api;
    this.defaultapi = api;
    this.vgApi.getDefaultMedia().subscriptions.canPlay.subscribe((response) => {
      this.vgApi.play();
    }, (error) => {
    });
  }

  close() {
    if (this.vgApi) {
      this.vgApi.getDefaultMedia().subscriptions.bufferDetected.subscribe((response) => {
        if (response) {
          this.showPopUp = false;
          setTimeout(() => {
            this.showPopUp = true;
            this.vgApi = this.defaultapi;
          }, 10);
        }
      });
    }
  }
}
