import { Component, Input } from '@angular/core';

@Component({
  selector: 'yim-ui-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent {
  @Input() images = [];
  public showingImageIndex: number = 0;

  prevClick() {
    if (0 < this.showingImageIndex) {
      this.showingImageIndex--;
    }
  }

  nextClick() {
    if (this.showingImageIndex < this.images.length - 1) {
      this.showingImageIndex++;
    }
  }

  dotClick(index) {
    this.showingImageIndex = index;
  }
}
