export enum Channel {
  Instagram = 'instagram',
  TikTok = 'tiktok',
  YouTube = 'youtube'
}

export interface IChannelOption<C extends Channel = any> {
  label: string;
  value: C;
}

export const CHANNEL_OPTIONS: IChannelOption[] = [
  { label: 'Instagram', value: Channel.Instagram },
  { label: 'TikTok', value: Channel.TikTok },
  { label: 'YouTube', value: Channel.YouTube },
];
