import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'yimifyImage'})
export class YimifyImagePipe implements PipeTransform {

  transform(imagePath: any, defaultImage = 'assets/img/placeholder.png'): any {
    if (imagePath && (imagePath.includes('https://') || imagePath.includes('http://'))) {
      return imagePath;
    } else {
      return defaultImage;
    }
  }
}

@Pipe({name: 'yimifyImageBackground'})
export class YimifyImageBackgroundPipe implements PipeTransform {

  transform(imagePath: any, defaultImage = 'assets/img/placeholder.png'): any {
    const pipe = new YimifyImagePipe();
    return `url(${pipe.transform(imagePath, defaultImage)})`;
  }
}
