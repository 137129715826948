import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppAsideModule, AppBreadcrumbModule, AppFooterModule, AppHeaderModule, AppSidebarModule } from '@coreui/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SEARCH_DEMO, SEARCH_INFLUENCER_ITEMS_PER_REQUEST } from '@yim/search/constants';
import { createTranslateLoader, ServerModule } from '@yim/server';
import {API, DEFAULT_LANG, FILE_PREFIX_URL, API_ADVERTISER_ENDPOINT, IS_PRODUCTION} from '@yim/server/constance';
import { TOOLTIP_USE_BROWSERLANG } from '@yim/ui/constants';
import { ToasterModule, ToasterService } from 'angular2-toaster';
import BugsnagErrorHandler from 'bugsnag-angular';
import bugsnag from 'bugsnag-js';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { AccordionModule } from 'ngx-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ANIMATION_TYPES, LoadingModule } from 'ngx-loading';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { PaymentSuccessComponent } from './components';
import { DefaultLayoutComponent } from './containers';
import { YimifyAppHeaderComponent } from './containers/default-layout/header/yimify-app-header.component';
import {
  YimifyAppSidebarProfileComponent,
} from './containers/default-layout/sidebar/profile/yimify-app-sidebar-profile.component';
import { YimifyAppSideBarComponent } from './containers/default-layout/sidebar/yimify-app-sidebar.component';
import { AuthGuard } from './guards/auth.guard';
import { TokenInterceptor } from './interceptor/token.interceptor';
import {
  ApplicationsService,
  BlogService,
  CookiesService,
  MessageService,
  NewsService,
  NotificationService,
  PaymentService,
  ProfileService,
  UserService,
} from './services';
import { SharedCustomModule } from './shared.module';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { ChangePasswordComponent, ForgotPasswordComponent, LoginComponent, ResetPasswordComponent } from './views/user';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

// Import bugsnag-js and bugsnag-angular
const APP_CONTAINERS = [
  DefaultLayoutComponent,
  YimifyAppHeaderComponent,
  YimifyAppSideBarComponent,
  YimifyAppSidebarProfileComponent
];

export const providers: any[] = [
  AuthGuard,
  UserService,
  CookiesService,
  ProfileService,
  ToasterService,
  MessageService,
  NewsService,
  PaymentService,
  ApplicationsService,
  BlogService,
  NotificationService,
  {provide: SEARCH_DEMO, useValue: false },
  {provide: IS_PRODUCTION, useValue: environment.production },
  {provide: TOOLTIP_USE_BROWSERLANG, useValue: false },
  {provide: API, useValue: environment.API },
  {provide: API_ADVERTISER_ENDPOINT, useValue: environment.advertiserEndpoint },
  {provide: DEFAULT_LANG, useValue: environment.Default_language },
  {provide: SEARCH_INFLUENCER_ITEMS_PER_REQUEST, useValue: environment.searchInfluencerItemsPerRequest },
  {provide: FILE_PREFIX_URL, useValue: environment.FILE_PREFIX_URL },
  {provide: LocationStrategy, useClass: PathLocationStrategy},
  {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true}
];

export const declarations = [
  AppComponent,
  ...APP_CONTAINERS,
  P404Component,
  P500Component,
  LoginComponent,
  ForgotPasswordComponent,
  ResetPasswordComponent,
  ChangePasswordComponent,
  PaymentSuccessComponent
];

export const imports = [
  ReactiveFormsModule,
  FormsModule,
  BrowserModule,
  BrowserAnimationsModule,
  AppRoutingModule,
  AppAsideModule,
  AppBreadcrumbModule.forRoot(),
  AppFooterModule,
  AppHeaderModule,
  AppSidebarModule,
  BsDropdownModule.forRoot(),
  TabsModule.forRoot(),
  ChartsModule,
  SharedCustomModule,
  HttpClientModule,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: (createTranslateLoader),
      deps: [HttpClient]
    }
  }),
  ToasterModule.forRoot(),
  AccordionModule.forRoot(),
  LoadingModule,
  ServerModule,
  LoadingModule.forRoot({
    animationType: ANIMATION_TYPES.circleSwish,
    backdropBackgroundColour: 'rgba(0,0,0,0.1)',
    backdropBorderRadius: '4px',
    primaryColour: '#000000',
    secondaryColour: '#ffffff',
    tertiaryColour: '#ffffff',
    fullScreenBackdrop: true
  })
];

if (!!environment['bugsnagAPIKey']) {
  const bugsnagClient = bugsnag(environment['bugsnagAPIKey']);
  // create a factory which will return the bugsnag error handler
  const errorHandlerFactory = () => {
    return new BugsnagErrorHandler(bugsnagClient);
  };

  providers.push({provide: ErrorHandler, useFactory: errorHandlerFactory});
}

@NgModule({
  imports,
  declarations,
  providers,
  bootstrap: [AppComponent]
})
export class AppModule {

}
