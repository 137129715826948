import { Component, OnInit, Input, HostBinding, HostListener, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'yim-server-influencer-stats-detail',
  templateUrl: './influencer-stats-detail.component.html',
  styleUrls: [
    '../influencer-stats/influencer-stats.component.scss',
    './influencer-stats-detail.component.scss'
  ]
})
export class InfluencerStatsDetailComponent implements OnInit {

  @HostBinding('class.demo-opacity') @Input() isDemo = false;
  @Input() stats: { [key: string]: any; };
  @Input() locationByCountries = [];
  @Input() locationByCities = [];
  @Input() likesIncrement: number;
  @Input() followerIncrement: number;
  @Input() followingIncrement: number;
  @Input() statHistoryLabels = [];
  @Input() likesStatHistoryData = [];
  @Input() followingStatHistoryData = [];
  @Input() followersStatHistoryData = [];
  @Input() postListToShow = [];
  @Input() engagementRateChartData = [];
  @Input() audienceSplitChartData = [];
  @Input() engagementRateChartLabels = [];
  @Input() audienceSplitChartLabels = [];
  @Input() engagementSpreadChartData = [];
  @Input() engagementSpreadChartLabels = [];
  @Input() profileFixedEngagementRate = null;
  @Input() similarAccountEngagementRate = null;
  @Input() openUsingPopUp = true;
  @Input() postShowing: 'popular' | 'sponsored';
  @Input() chartReady = true;

  @Output() changePostToShow = new EventEmitter<'popular' | 'sponsored'>();
  @Output() chartClicked = new EventEmitter<any>();
  @Output() hideClicked = new EventEmitter();

  constructor() { }

  ngOnInit() { }

}
