import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Common } from '@yim/server/models';
import { LoaderService } from '@yim/ui/services';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { News } from '../models';

@Injectable()
export class NewsService {
  private static news: BehaviorSubject<News[]> = new BehaviorSubject([]);
  private static categories: BehaviorSubject<Common[]> = new BehaviorSubject([]);

  constructor(private http: HttpClient) {
  }

  private getNewsForType(category, type): Observable<News[]> {
    return this.http.get<News[]>(`${environment.API}/news/list?category=${category}&type=${type}`);
  }

  private refreshNewsForType(category, type): void {
    requestAnimationFrame(() => {
      LoaderService.show();
      this.getNewsForType(category, type).subscribe(news => {
        LoaderService.hide();
        NewsService.news.next([
          ...NewsService.news.getValue().filter(c => (
            (c.type !== type) && (c.news_category_id !== category)
          )),
          ...news.map(v => Object.assign({}, v))
        ]);
      }, (error) => {
        LoaderService.hide();
      });
    });
  }

  getNewsCategoriesData(): Observable<Common[]> {
    return this.http.get<Common[]>(`${environment.API}/news/categories`);
  }

  refreshNewsCategories(): void {
    this.getNewsCategoriesData().subscribe(categories => {
      NewsService.categories.next(categories);
    });
  }

  getNewsCategories(): Observable<Common[]> {
    this.refreshNewsCategories();
    return NewsService.categories.asObservable();
  }

  getNews(category, type) {
    this.refreshNewsForType(category, type);
    return NewsService.news.asObservable().pipe(
      map(campaigns => campaigns.filter(c => (c.type === type) && (c.news_category_id === category)))
    );
  }

  getAdvertiserNews(type) {
    this.refreshNewsForType(2, type);
    return NewsService.news.asObservable().pipe(
      map(campaigns => campaigns.filter(c => (c.news_category_id === 2)))
    );
  }
}
