import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[appYimifyDropdown]'
})
export class YimifyDropdownDirective implements OnInit {

  private alreadyClicked = false;
  private isOpen = false;
  private outsideClickListener: Function;
  private escKeyListener: Function;
  private dropdownMenuElement = null;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit(): void {
    for (const childElement of this.elementRef.nativeElement.childNodes) {
      if (childElement.classList.contains('dropdown-menu')) {
        this.dropdownMenuElement = childElement;
      }
    }
  }

  @HostListener('click')
  toggleDropDown() {
    this.alreadyClicked = true;
    if (!this.isOpen && this.dropdownMenuElement !== null) {
      this.show();
      this.outsideClickListener = this.renderer.listen('document', 'click',
        (documentEvent) => {
          if (!this.alreadyClicked) {
            this.hide();
          } else {
            this.alreadyClicked = false;
          }
        });
      this.escKeyListener = this.renderer.listen(this.elementRef.nativeElement, 'keyup.esc',
        (escKeyEvent) => {
          this.hide();
        });
    } else {
      this.hide();
    }
  }

  show() {
    if (this.dropdownMenuElement != null) {
      this.dropdownMenuElement.classList.add('show');
      this.isOpen = true;
    }
  }

  hide() {
    if (this.dropdownMenuElement != null) {
      this.dropdownMenuElement.classList.remove('show');
      this.isOpen = false;
    }
    this.outsideClickListener();
    this.escKeyListener();
  }

}
