import { Injectable } from '@angular/core';

@Injectable()
export class HelperService {

  static status = [];
  static mystatus = [];
  static payments = [];
  static types = [];
  static channels = [];

  static userCurrency = 'EUR';

  static isVisibleInView(el, parent?: HTMLElement) {
    const childRect = el.getBoundingClientRect();
    const parentRect = parent || el.parentNode;

    if (childRect.top <= parentRect.bottom) {
      return false;
    }
    if ((childRect.top + childRect.height) <= parentRect.top) {
      return false;
    }
    return true;
  }


  static generateSlides(list, itemPerSlide = 4) {
    const slides = [];
    let slide = [];
    list.forEach((item) => {
      slide.push(item);
      if (slide.length > itemPerSlide - 1) {
        slides.push(slide);
        slide = [];
      }
    });
    if (slide.length !== 0) {
      slides.push(slide);
    }
    return slides;
  }

  static valueToGetReqString(arr) {
    const getStringArray = [];
    for (const key in arr) {
      if (arr.hasOwnProperty(key) && arr[key] !== null) {
        getStringArray.push(key + '=' + encodeURIComponent(arr[key]));
      }
    }
    return getStringArray.join('&');
  }

  static getLabelValuePair(array) {
    const filteredArray = [];
    for (const key in array) {
      if (array.hasOwnProperty(key)) {
        if (key.length > 0) {
          filteredArray.push({label: array[key], value: key});
        } else {
          filteredArray.push({label: array[key], value: null});
        }
      }
    }
    return filteredArray;
  }

  static setCommonFields() {
    this.status = this.getKeyValPairFromStorage('status');
    this.mystatus = this.getKeyValPairFromStorage('mystatus');
    this.payments = this.getKeyValPairFromStorage('payments');
    this.types = this.getKeyValPairFromStorage('types');
    this.channels = this.getKeyValPairFromStorage('channels');

    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.advertiser) {
      const userCurrency = JSON.parse(localStorage.getItem('user')).advertiser.currency;
      if (userCurrency) {
        this.userCurrency = userCurrency;
      }
    }
  }

  static getKeyValPairFromStorage(localStorageKey) {
    const items = JSON.parse(localStorage.getItem(localStorageKey));
    const arr = [];
    for (const key in items) {
      if (items.hasOwnProperty(key)) {
        const name = items[key]['name'];
        arr[name.toLowerCase()] = items[key]['id'];
      }
    }
    return arr;
  }

  static getLabelFromId(id, arrName) {
    for (const key in this[arrName]) {
      if (this[arrName].hasOwnProperty(key) && +this[arrName][key] === +id) {
        return key;
      }
    }
    return '';
  }

  static scrollToFragment(fragment) {
    if (fragment) {
      setTimeout(() => {
        fragment.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
      }, 10);
    }
  }

  static scrollToElement(element) {
    if (element) {
      setTimeout(() => {
        this.scrollParentToChild(element.parentNode, element);
      }, 10);
    }
  }

  static scrollParentToChild(parent, child) {
    if (!parent || !child) {
      return;
    }
    const parentRect = parent.getBoundingClientRect();
    const parentViewableArea = {
      height: parent.clientHeight,
      width: parent.clientWidth
    };
    const childRect = child.getBoundingClientRect();
    const isViewable = (childRect.top >= parentRect.top) && (childRect.top <= parentRect.top + parentViewableArea.height);
    if (!isViewable) {
      parent.scrollTo({
        top: (childRect.top + parent.scrollTop) - parentRect.top,
        left: 0,
        behavior: 'smooth'
      });
    }
  }


  static isVisible(el) {
    let rect = el.getBoundingClientRect();
    const top = rect.top;
    const height = rect.height;
    el = el.parentNode;
    do {
      rect = el.getBoundingClientRect();
      if (top <= rect.bottom === false) {
        return false;
      }
      if ((top + height) <= rect.top) {
        return false;
      }
      el = el.parentNode;
    } while (el !== document.body);
    return top <= document.documentElement.clientHeight;
  }

  static getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
    const totalPages = Math.ceil(totalItems / pageSize);
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    const pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    return {
      currentPage: currentPage,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  static checkNaN(val) {
    const parsedVal = parseFloat(val);
    return parsedVal !== parsedVal;
  }

}
