import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { API, API_ADVERTISER_ENDPOINT, FILE_PREFIX_URL } from './../constance';

// import { environment } from '../../environments/environment';

export type CampaignAddConditions = {
  [key in 'public' | 'application' | 'influencer_search']: {
    value: boolean,
    state?: 'disabled' | 'hidden'
  }
};

@Injectable({ providedIn: 'root' })
export class CampaignService {

  static setCampaignProgressCreated(campaignId) {
    localStorage.setItem('campaign_progress', JSON.stringify({id: campaignId, uploadedMedia: false, influencerAdded: false}));
  }

  static setCampaignProgressMediaUploaded(campaignId) {
    this.setCampaignProgress(campaignId, 'uploadedMedia');
  }

  static setCampaignProgressInfluencerAdded(campaignId) {
    this.setCampaignProgress(campaignId, 'influencerAdded');
  }

  static setCampaignProgress(campaignId, progressStatus) {
    if (localStorage.getItem('campaign_progress')) {
      const progress = JSON.parse(localStorage.getItem('campaign_progress'));
      if (+campaignId === progress.id) {
        progress[progressStatus] = true;
        localStorage.setItem('campaign_progress', JSON.stringify(progress));
        if (progress.influencerAdded && progress.uploadedMedia) {
          localStorage.removeItem('campaign_progress');
        }
      }
    }
  }

  static getIncompleteCampaignProgress() {
    return JSON.parse(localStorage.getItem('campaign_progress'));
  }

  static setIncompleteCampaignProgressAsDone() {
    localStorage.removeItem('campaign_progress');
  }

  constructor(
    @Inject(API) private readonly api: string,
    @Inject(FILE_PREFIX_URL) private readonly filePrefixUrl: string,
    @Inject(API_ADVERTISER_ENDPOINT) private readonly endpoint: string,
    private http: HttpClient) { }

  getCampaignList() {
    return this.http.get<any>(`${this.api}/${this.endpoint}/campaign/list`);
  }

  getCampaignTrackingList() {
    return this.http.get<any>(`${this.api}/${this.endpoint}/tracking`);
  }

  getCampaignTypes() {
    return this.http.get<any>(`${this.api}/${this.endpoint}/campaign/types`);
  }

  getCampaignPayments() {
    return this.http.get<any>(`${this.api}/${this.endpoint}/campaign/payments`);
  }

  getCampaignChannels() {
    return this.http.get<any>(`${this.api}/${this.endpoint}/campaign/channels`);
  }

  getCampaignStatus() {
    return this.http.get<any>(`${this.api}/${this.endpoint}/campaign/status`);
  }

  getCampaignMyStatus() {
    return this.http.get<any>(`${this.api}/${this.endpoint}/campaign/mystatus`);
  }

  getCampaignOverviewTabsSetting() {
    return this.http.get<any>(`${this.api}/${this.endpoint}/campaign/showoverview`);
  }

  async getAllRequiredData() {
    const types = this.getCampaignTypes();
    const payments = this.getCampaignPayments();
    const channels = this.getCampaignChannels();
    const status = this.getCampaignStatus();
    const myStatus = this.getCampaignMyStatus();
    const overviewTabs = this.getCampaignOverviewTabsSetting();

    return forkJoin([types, payments, channels, status, myStatus, overviewTabs])
      .pipe(catchError(error => of(error))).toPromise();
  }

  getCampaignDetails(id) {
    return this.http.get(`${this.api}/${this.endpoint}/campaign/detail/${id}`);
  }

  createNewCampaign(campaignData) {
    return this.http.post(`${this.api}/${this.endpoint}/campaign/create`, campaignData);
  }

  editCampaign(campaign_id, values) {
    return this.http.put(`${this.api}/${this.endpoint}/campaign/update/${campaign_id}`, values);
  }

  uploadMedia(campaignMedia) {
    const header = new HttpHeaders();
    header.append('Content-Type', 'multipart/form-data');
    header.append('Accept', 'multipart/form-data');
    return this.http.post<any>(`${this.api}/${this.endpoint}/campaign/media`, campaignMedia, {headers: header});
  }

  addInfluencerToCampaign(campaignId, influencerId, socialId) {
    const postValues = {campaignId: campaignId, influencerId: influencerId, socialId: socialId};
    return this.http.post<any>(`${this.api}/${this.endpoint}/campaign/addinfluencer`, postValues);
  }

  removeInfluencerToCampaign(campaignId, influencerId, socialId) {
    const postValues = {campaignId: campaignId, influencerId: influencerId, socialId: socialId};
    return this.http.post<any>(`${this.api}/${this.endpoint}/campaign/removeinfluencer`, postValues);
  }

  getCampaignTrackingDetail(campaignId) {
    return this.http.get<any>(`${this.api}/${this.endpoint}/tracking/campaign?id=${campaignId}`);
  }

  changeCampaignStatus(statusToChange, campaignId) {
    return this.http.get<any>(`${this.api}/${this.endpoint}/campaign/setstatus/${statusToChange}/${campaignId}`);
  }

  setInfluencerPrice(postValues) {
    return this.http.post<any>(`${this.api}/${this.endpoint}/campaign/setinfluencerprice`, postValues);
  }

  checkCampaignCreationAllowed() {
    return this.http.get<{
      status: boolean,
      message: string,
      conditions: CampaignAddConditions
    }>(`${this.api}/${this.endpoint}/campaign/check`);
  }

  deleteCampaignMedia(campaignId, file) {
    // removing file prefix url; using environment variable as different environment will have different URL
    file = file.replace(this.filePrefixUrl, '');
    const values = {campaign: campaignId, file: file};
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: values,
    };
    return this.http.delete<any>(`${this.api}/${this.endpoint}/campaign/media`, options);
  }

}
