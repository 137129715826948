import { API_ADVERTISER_ENDPOINT } from '@yim/server/constance';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { CookiesService } from './cookies.service';
import { ProfileService } from './profile.service';

@Injectable()
export class UserService {

  public readonly tokenSubject = new BehaviorSubject<string>(localStorage.getItem('token'));

  public get Token() {
    return this.tokenSubject.pipe(
      filter(v => v === this.tokenSubject.getValue())
    );
  }

  constructor(
    private http: HttpClient,
    private inj: Injector,
    @Inject(API_ADVERTISER_ENDPOINT) private readonly endpoint: string,
  ) { }

  login(user) {
    return this.http.post<any>(environment.API + '/' + this.endpoint + '/user/login', user);
  }

  changePassword(user) {
    return this.http.post<any>(environment.API + '/' + this.endpoint + '/user/changepassword', user);
  }

  forgotPassword(user) {
    return this.http.post<any>(environment.API + '/' + this.endpoint + '/user/forgotpassword', user);
  }

  resetPassword(user) {
    return this.http.post<any>(environment.API + '/' + this.endpoint + '/user/resetpassword', user);
  }

  logout() {
    const _profile = this.inj.get(ProfileService);
    CookiesService.deleteCookie('yimify_news_category');
    _profile.emptyProfile();
    localStorage.clear();
  }

}
