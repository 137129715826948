import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yimNumber'
})
export class NumberPipe implements PipeTransform {

  private static getInt(value: number | any): number {
    if (typeof value === 'number') { return value; }
    return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
  }

  public static transform(value: number | any): any {
    return NumberPipe.getInt(value).toLocaleString('de');
  }

  transform(value: number | any): any {
    return NumberPipe.transform(value);
  }

}
