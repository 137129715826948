import 'rxjs/add/operator/toPromise';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { API, API_ADVERTISER_ENDPOINT, DEFAULT_LANG } from './../constance';
import { CampaignService } from './campaign.service';

// import { environment } from '../../environments/environment';
// import { navItems } from '../_nav';
@Injectable({ providedIn: 'root' })
export class LanguageService implements OnInit {
  public default_values: {
    default_language: string,
    // 'nav_items': navItems
    nav_items: any[]
  };

  public default_static_values: any;

  public values: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(
      @Inject(API) private readonly api: string,
      @Inject(DEFAULT_LANG) default_language: string,
      @Inject(API_ADVERTISER_ENDPOINT) private readonly endpoint: string,
      private _translate: TranslateService,
      private http: HttpClient,
      private _campaignService: CampaignService) {
    this.default_values = { default_language, nav_items: [] };
    this.default_static_values = JSON.parse(JSON.stringify(this.default_values));
    if (localStorage.getItem('user')) {
      const user_data = JSON.parse(localStorage.getItem('user'));
      const language = user_data['language'] === 'de-DE' ? 'de' : 'en';
      this.setLanguage(language);
    } else {
      this.setLanguage(default_language);
    }
  }

  ngOnInit() {
  }

  setLanguage(language: string) {
    this._translate.setDefaultLang(language);
    this.default_values['default_language'] = language;
    for (let i = 0; i < this.default_static_values['nav_items'].length; i++) {
      this.getTranslation(this.default_static_values['nav_items'][i]['name']).subscribe((response) => {
        this.default_values['nav_items'][i]['name'] = response;
      });
      if (this.default_static_values['nav_items'][i]['children'] && this.default_static_values['nav_items'][i]['children'].length > 0) {
        for (let j = 0; j < this.default_static_values['nav_items'][i]['children'].length; j++) {
          this.getTranslation(this.default_static_values['nav_items'][i]['children'][j]['name']).subscribe((response) => {
            this.default_values['nav_items'][i]['children'][j]['name'] = response;
          });
        }
      }
    }
    this.values.next(this.default_values);
  }

  getTranslation(term: string) {
    return this._translate.get(term);
  }

  changeUserLanguageAPI(language: string) {
    const data: any = {
      'language': language === 'de' ? 'de-DE' : 'en-US'
    };
    return this.http.post<any>(this.api + '/' + this.endpoint + '/user/changelanguage', data).toPromise();
  }

  changeUserLanguage(language: string) {
    this.changeUserLanguageAPI(language).then((status: boolean) => {
      if (status) {
        const user_data = JSON.parse(localStorage.getItem('user'));
        user_data['language'] = language === 'de' ? 'de-DE' : 'en-US';
        localStorage.setItem('user', JSON.stringify(user_data));
        this.setLanguage(language);
        this._campaignService.getAllRequiredData();
      }
    });
  }

  getTranslationUsingKeyString(key: string) {
    return this._translate.get(key);
  }

  getTranslatedString(string) {
    let translated_string = '';
    this.getTranslationUsingKeyString(string)
      .subscribe(
        translatedData => {
          translated_string = translatedData;
        }
      );
    return translated_string;
  }

  getTranslatedStringInstant(string) {
    return this._translate.instant(string);
  }
}
