import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yimLoremPicsum'
})
export class LoremPicsumPipe implements PipeTransform {

  transform({ width, height, blur }: { width: number, height: number, blur: number }): any {
    return `https://picsum.photos/${width}/${height}/?blur=${blur + Math.random()}`;
  }

}
