import { HttpClient } from '@angular/common/http';
import { API, API_ADVERTISER_ENDPOINT } from './../constance';
import { filter, tap, map, first, mergeMap, catchError } from 'rxjs/operators';
import { BehaviorSubject, Subscription, of, merge, Observable } from 'rxjs';
import { Inject, Injectable, Pipe } from '@angular/core';
import { fromPromise } from 'rxjs/internal-compatibility';
import 'rxjs-compat/add/operator/catch';

export enum CannotRunReason {
  AdBlock = 'adblock',
  Loading = 'loading',
  None = 'none'
}

@Injectable({ providedIn: 'root' })
export class CanRunService {

  private canBehav = new BehaviorSubject(CannotRunReason.Loading);

  private adBlockCheckSub: Subscription;

  public get Reason() {
    let before: CannotRunReason | undefined;
    return this.canBehav.pipe(
      filter(v => before !== v),
      filter(v => v !== CannotRunReason.Loading), tap(v => before = v)
    );
  }

  public get CannotReason() {
    return this.Reason.pipe(filter(v => v !== CannotRunReason.None));
  }


  constructor(
    @Inject(API) api: string,
    @Inject(API_ADVERTISER_ENDPOINT) endpoint: string,
    private http: HttpClient,
  ) {
    this.adBlockCheckSub = this.http.get(`${api}/${endpoint}/search/getinterests`)
      .catch(e => fromPromise(this.hasAdBlock()).pipe(
        first(),
        map(v => v ? CannotRunReason.AdBlock : CannotRunReason.None),
      )).pipe(
        map(v => typeof v === 'string' ? (v as CannotRunReason) : CannotRunReason.None)
      )
      .subscribe(v => this.canBehav.next(v));
  }

  private async hasAdBlock() {
    try {
      await fetch('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js', {
        method: 'HEAD',
        mode: 'no-cors',
      });
      return false;
    } catch (e) {
      return true;
    }
  }
}
