import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';

import { SEARCH_DEMO } from './../../../../search/src/lib/constants';
import { API, API_ADVERTISER_ENDPOINT } from './../constance';
import { Channel } from './../models/channel';

// import { environment } from '../../environments/environment';
@Injectable({ providedIn: 'root' })
export class InfluencerService {

  private allowAudienceFilterSubject = new BehaviorSubject<boolean>(false);
  private allowInfluencerFilterSubject = new BehaviorSubject<boolean>(false);

  private set allowAudienceFilter(value: boolean) { this.allowAudienceFilterSubject.next(value); }
  private get allowAudienceFilter() { return this.allowAudienceFilterSubject.getValue(); }

  private set allowInfluencerFilter(value: boolean) { this.allowInfluencerFilterSubject.next(value); }
  private get allowInfluencerFilter() { return this.allowInfluencerFilterSubject.getValue(); }


  constructor(
    @Inject(API) private readonly api: string,
    @Inject(SEARCH_DEMO) private readonly isSearchDemo = false,
    @Inject(API_ADVERTISER_ENDPOINT) private readonly endpoint: string,
    private http: HttpClient,
  ) { }

  isAudienceFilterAllowed() {
    return this.allowAudienceFilterSubject.asObservable();
  }

  isInfluencerFilterAllowed() {
    return this.allowInfluencerFilterSubject.asObservable();
  }

  search(searchFilter) {
    return this.http.get<any>(`${this.api}/${this.endpoint}/search/search?${searchFilter}`);
  }

  searchDetail(searchDetailFilter) {
    return this.http.get<any>(`${this.api}/${this.endpoint}/search/${this.isSearchDemo ? 'demo' : 'detail'}?${searchDetailFilter}`);
  }

  getFilters() {
    return this.http.get<any>(`${this.api}/${this.endpoint}/search/getfilter`);
  }

  setFilters() {
    this.getFilters().subscribe((response) => {
      if (response.hasOwnProperty('audience')) {
        this.allowAudienceFilter = response.audience;
      }
      if (response.hasOwnProperty('influencer')) {
        this.allowInfluencerFilter = response.influencer;
      }
    });
  }

  getOptions() {
    const interests = this.getInterests();
    const countries = this.getCountries();
    return forkJoin([interests, countries]);
  }

  getInterests() {
    return this.http.get<any>(`${this.api}/${this.endpoint}/search/getinterests`);
  }

  getGeo(searchItem) {
    return this.http.get<any>(`${this.api}/${this.endpoint}/search/getgeo?name=${searchItem}`);
  }

  getCountries() {
    return this.http.get<any>(`${this.api}/${this.endpoint}/search/getcountries`);
  }

  getInfluencerStats(influenceId: number, channel: Channel) {
    return this.http.get<any>(
      `${this.api}/${this.endpoint}/influencer/bigsetcard${this.isSearchDemo ? 'demo' : '/' + [channel, influenceId].join('/')}`);
  }

  getWordCloud(tag) {
    return this.http.get<any>(`${this.api}/${this.endpoint}/search/wordcloud?tag=${tag}`);
  }

}

