import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'yim-server-influencer-stats-followers-location',
  templateUrl: './followers-location.component.html',
  styleUrls: [
    '../../../influencer-stats/influencer-stats.component.scss',
    './followers-location.component.scss'
  ]
})
export class FollowersLocationComponent implements OnInit {

  @Input() locationByCountries = [];
  @Input() locationByCities = [];

  trackByValueWithKey(index: number, value: { key: string }) {
    return value.key;
  }

  trackByValueWithId(index: number, value: { id: any }) {
    return value.id;
  }

  constructor() { }

  ngOnInit() {
  }

}
