import { API_ADVERTISER_ENDPOINT } from '@yim/server/constance';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { forkJoin } from 'rxjs';

@Injectable()
export class PaymentService {

  static PAYMENT_3DS_AUTHENTICATION_COMPLETE = '3DS-authentication-complete';

  constructor(
    private http: HttpClient,
    @Inject(API_ADVERTISER_ENDPOINT) private readonly endpoint: string
  ) { }

  getOutstandingPayments() {
    return this.http.get(environment.API + `/${this.endpoint}/payment/outstanding`);
  }

  getCompletePayments() {
    return this.http.get(environment.API + `/${this.endpoint}/payment/completed`);
  }

  getAllPayments() {
    const outStandingPayments = this.getOutstandingPayments();
    const completePayments = this.getCompletePayments();
    return forkJoin([outStandingPayments, completePayments]);
  }

  payPayment(postValues) {
    return this.http.post(environment.API + `/${this.endpoint}/payment/pay`, postValues);
  }

  downloadInvoices(campaignId) {
    return this.http.get(environment.API + `/${this.endpoint}/payment/downloadallinvoices/${campaignId}`);
  }

  authorizePayment(postValues) {
    return this.http.post(environment.API + `/${this.endpoint}/payment/authorize`, postValues);
  }

}
