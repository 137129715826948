import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { HelperService } from '@yim/ui/services';

@Pipe({
  name: 'yimifyCurrency',
})
export class YimifyCurrencyPipe implements PipeTransform {
  transform(value: number, display: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = 'symbol',
            digitsInfo: string = '0.2-2',
            locale: string = 'en-US',
  ): string | null {
    const currencyCode = HelperService.userCurrency;
    return formatCurrency(value, locale, getCurrencySymbol(currencyCode, 'wide'),
      currencyCode, digitsInfo);
  }
}
