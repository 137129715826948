import { API_ADVERTISER_ENDPOINT } from '@yim/server/constance';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class ApplicationsService {

  constructor(
    private http: HttpClient,
    @Inject(API_ADVERTISER_ENDPOINT) private readonly endpoint: string,
  ) { }

  getCampaignsHavingApplications() {
    return this.http.get<any>(`${environment.API}/${this.endpoint}/application/campaigns`);
  }

  getCampaignApplications(id) {
    return this.http.get<any>(`${environment.API}/${this.endpoint}/application/campaign?id=` + id);
  }

  acceptApplication(campaignId, influencerId) {
    const postValues = {campaignId: campaignId, influencerId: influencerId};
    return this.http.post<any>(`${environment.API}/${this.endpoint}/application/accept`, postValues);
  }

  rejectApplication(campaignId, influencerId) {
    const postValues = {campaignId: campaignId, influencerId: influencerId};
    return this.http.post<any>(`${environment.API}/${this.endpoint}/application/reject`, postValues);
  }

  rejectAllApplicationForCampaign(campaignId) {
    const postValues = {campaignId};
    return this.http.post<any>(`${environment.API}/${this.endpoint}/application/rejectall`, postValues);
  }

  endApplicationPeriodForCampaign(campaignId) {
    const postValues = {campaignId};
    return this.http.post<any>(`${environment.API}/${this.endpoint}/application/endapplication`, postValues);
  }
}
