import { Component, OnInit, Input } from '@angular/core';
import { NumberPipe } from '@yim/ui/pipes/number.pipe';

@Component({
  selector: 'yim-server-influencer-stats-meta-graphs',
  templateUrl: './meta-graphs.component.html',
  styleUrls: [
    '../../../influencer-stats/influencer-stats.component.scss',
    './meta-graphs.component.scss'
  ]
})
export class MetaGraphsComponent implements OnInit {

  @Input() likesIncrement: number;
  @Input() followerIncrement: number;
  @Input() followingIncrement: number;
  @Input() likesStatHistoryData = [];
  @Input() statHistoryLabels = [];
  @Input() followersStatHistoryData = [];
  @Input() followingStatHistoryData = [];
  @Input() chartReady = true;

  public colorPink: any[] = [
    {backgroundColor: 'rgba(233,70,131,0.30)'}
  ];

  public statHistoryOption = {
    responsive: true,
    elements: {
      point: {
        radius: 0
      }
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    },
    animation: {
      onProgress: function () {
        const chart = this;
        const ctx = chart.ctx;
        const datasets = this.config.data.datasets;

        const gradient = ctx.createLinearGradient(0, 500, 200, 500);
        gradient.addColorStop(0, 'rgba(59,101,122,0.8)');
        gradient.addColorStop(1, 'rgba(233,70,131,0.8)');

        for (let i = 0; i < datasets.length; i++) {
          datasets[i].borderColor = gradient;
        }
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          callback: function (label, index, labels) {
            const tempData = this.chart.config.data.datasets[0].data;
            const data = [];
            for (let i = 0; i < tempData.length; i++) {
              data[i] = tempData[i];
            }
            data.sort((firstVal, secondVal) => firstVal - secondVal);
            data.reverse();
            return NumberPipe.transform(data[index]);
          },
          autoSkip: false
        },
        beforeBuildTicks: function (chartObj) {
          const tempData = chartObj.chart.config.data.datasets[0].data;
          const data = [];
          for (let i = 0; i < tempData.length; i++) {
            data[i] = tempData[i];
          }
          data.sort((firstVal, secondVal) => firstVal - secondVal);
          const stepSize = (data[data.length - 1] - data[0]) / data.length;
          chartObj.options.ticks.min = data[0] - stepSize;
          chartObj.options.ticks.max = data[data.length - 1] + stepSize;
          chartObj.options.ticks.stepSize = (chartObj.options.ticks.max - chartObj.options.ticks.min) / data.length;
        }
      }]
    }
  };

  constructor() { }

  ngOnInit() {
  }

}
