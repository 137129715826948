import { API_ADVERTISER_ENDPOINT } from '@yim/server/constance';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { INavData } from '@coreui/angular';
import { LanguageService } from '@yim/server/services';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from './../../../../../environments/environment';

const STORAGE_NAME = 'navigation';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  private behav = new BehaviorSubject<INavData[]>(JSON.parse(localStorage.getItem(STORAGE_NAME)));
  private sub: Subscription;

  public get NavigationItems() {
    return this.behav.pipe(
      map(v => (v || []).map(item => ({
          ...item,
          name: this.languageService.getTranslatedStringInstant(item.name)
        }))
      )
    );
  }

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    @Inject(API_ADVERTISER_ENDPOINT) private readonly endpoint: string,
  ) {

    if (!environment.production) {
      this.NavigationItems.subscribe(v => {
        console.groupCollapsed('Navigation data');
        console.table(v);
        console.groupEnd();
      });
    }

    if (!localStorage.getItem(STORAGE_NAME) && localStorage.getItem('user')) {
      this.refresh();
    }
  }

  private cacheData(data: INavData[]) {
    localStorage.setItem(STORAGE_NAME, JSON.stringify(data));
    this.behav.next(data);
  }

  refresh() {
    if (this.sub) { this.sub.unsubscribe(); }
    this.sub = this.http.get<INavData[]>(`${environment.API}/${this.endpoint}/package/navigation`).subscribe(v => {
      this.cacheData(v);
    });
  }
}
