import 'rxjs/add/operator/takeWhile';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from '@yim/server/services';

import { UserService } from '../../../../services';

@Component({
  selector: 'yimify-app-sidebar-profile',
  templateUrl: './yimify-app-sidebar-profile.component.html',
  styleUrls: ['./yimify-app-sidebar-profile.component.scss']
})
export class YimifyAppSidebarProfileComponent implements OnInit, OnDestroy {

  private alive: boolean = true;
  public profile_img: string = '';
  public profile_name: string;

  constructor(private _user_service: UserService,
              private _router: Router,
              private _lang: LanguageService) {
  }

  selected_language: string = '';

  ngOnInit() {
    const profileData = JSON.parse(localStorage.getItem('user'));
    this.profile_name = profileData.firstname + ' ' + profileData.lastname;
    this.profile_img = profileData.picture;
    if (this.profile_img === null || this.profile_img === '') {
      this.profile_img = 'assets/img/avatars/user-default.png';
    }
    this._lang.values
      .takeWhile(() => this.alive)
      .subscribe((response: any) => {
        this.selected_language = response.default_language;
      });
  }

  changeLanguage(lang: string) {
    this._lang.changeUserLanguage(lang);
  }

  logout() {
    setTimeout(() => {
      this._user_service.logout();
      this._router.navigate(['/login']);
    }, 1000);
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
