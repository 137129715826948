import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '@yim/ui/services';

import { UserService } from '../../../services';
import { ConfirmPasswordValidation } from '../../../validators/confirm-password.validator';

@Component({
  templateUrl: 'reset_password.component.html',
  styleUrls: ['./reset_password.component.scss']
})
export class ResetPasswordComponent {

  public resetPasswordForm: FormGroup;
  public isChanged: boolean = false;
  public api_error = {};
  public token: string = '';

  constructor(private _router: Router,
              private fb: FormBuilder,
              private _user_service: UserService,
              private _route: ActivatedRoute) {

    this.resetPasswordForm = fb.group({
        'password': ['', [Validators.required, Validators.minLength(6)]],
        'passwordRepeat': ['', [Validators.required, Validators.minLength(6)]]
      },
      {
        validator: ConfirmPasswordValidation.MatchPassword
      });

    this._route.params.subscribe(params => {
      if (params['token']) {
        this.token = params['token'];
      } else {
        this._router.navigate(['/login']);
      }
    });
  }


  resetPassword(user_values) {
    user_values['token'] = this.token;
    this.api_error = {};
    requestAnimationFrame(() => {
      LoaderService.show();
      this._user_service.resetPassword(user_values).subscribe((response) => {
          LoaderService.hide();
          this.isChanged = true;
          setTimeout(() => {
            this._router.navigate(['/login']);
          }, 5000);
        },
        (error) => {
          LoaderService.hide();
          this.api_error = error.error.messages;
          if (this.api_error['token']) {
            setTimeout(() => {
              this._router.navigate(['/login']);
            }, 5000);
          }
        });
    });
  }

}
