import { Component, Input } from '@angular/core';

import { FormControl } from '@angular/forms';
import { ValidationService } from '../../services';

@Component({
  selector: 'yim-ui-validation-message',
  template: `
    <alert type="danger" class="error-span" *ngIf="errorMessage !== null">{{errorMessage}}</alert>`,
  styleUrls: ['./validation-message.component.scss']
})

export class ValidationMessagesComponent {
  @Input() control: FormControl;
  @Input() validationname: string;

  constructor() {
  }

  get errorMessage() {
    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
        return ValidationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName], this.validationname);
      }
    }
    return null;
  }
}
