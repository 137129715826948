import { Component, Input, OnInit, HostBinding, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'yim-server-influencer-info',
  templateUrl: './influencer-info.component.html',
  styleUrls: [
    '../influencer-stats/influencer-stats.component.scss',
    './influencer-info.component.scss'
  ]
})
export class InfluencerInfoComponent implements OnInit {

  @HostBinding('class.demo-opacity') @Input() isDemo = false;
  @Input() stats: { [key: string]: any; };

  @Input() followerIncrement: number;
  @Input() likesIncrement: number;

  @Input() hashtags: { name: string }[];
  @Input() mentions: { name: string }[];
  @Input() openUsingPopUp = true;

  @Output() hideClicked = new EventEmitter();

  trackByValueWithName(index: number, value: { name: string }) {
    return value.name;
  }


  constructor() { }

  ngOnInit() {
  }

}
