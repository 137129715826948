import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { TrackPageComponent } from '@yim/ui/components/track-page.component';
import { CarouselModule, ModalModule, TooltipModule } from 'ngx-bootstrap';
import { AlertModule } from 'ngx-bootstrap/alert';

import { CarouselComponent, TooltipComponent, ValidationMessagesComponent } from './components';
import { YimifyDropdownDirective } from './directives';
import {
  FileNamePipe,
  FileTypePipe,
  YimifyCurrencyPipe,
  YimifyDatePipe,
  YimifyImageBackgroundPipe,
  YimifyImagePipe,
} from './pipes';
import { LoremPicsumPipe } from './pipes/lorem-picsum.pipe';
import { NumberPipe } from './pipes/number.pipe';
import { ValidationService } from './services';


const directives = [
  YimifyDropdownDirective
];

const pipes = [
  YimifyImagePipe,
  YimifyCurrencyPipe,
  YimifyImageBackgroundPipe,
  YimifyDatePipe,
  FileTypePipe,
  FileNamePipe
];

const components = [
  TooltipComponent,
  CarouselComponent,
  ValidationMessagesComponent,
  TrackPageComponent
];

@NgModule({
  declarations: [...pipes, ...components, ...directives, LoremPicsumPipe, NumberPipe],
  imports: [
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    AlertModule.forRoot(),
    NgSelectModule,
    CarouselModule,
    CommonModule
  ],
  exports: [
    ...directives,
    ...pipes,
    ...components,
    ModalModule,
    TooltipModule,
    AlertModule,
    CarouselModule,
    NgSelectModule,
    TooltipComponent,
    LoremPicsumPipe,
    NumberPipe
  ],
  providers: [ValidationService]
})
export class UiModule { }
